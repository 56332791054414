import { css } from '@emotion/css';

export const fieldGroupCx = css`
  .field-label {
    font-size: 0.8125rem;
    margin-bottom: 0.375rem;
  }

  .field-info {
    margin-top: 0.5rem;

    .field-info-item {
      font-size: 0.75rem;
      &.error {
        color: #f44336;
      }
    }
  }
`;
