import * as yup from 'yup';
import utils from 'utils';

yup.addMethod(yup.string, 'phoneFormat', function (errorMessage) {
  return this.test(`test-phone-format`, errorMessage, function (value) {
    const { path, createError } = this;
    const isValid = value ? utils.validator.phoneValidator(value) : true;
    return isValid || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'emailFormat', function (errorMessage) {
  return this.test(`test-email-format`, errorMessage, function (value) {
    const { path, createError } = this;
    const isValid = value ? utils.validator.emailValidator(value) : true;
    return isValid || createError({ path, message: errorMessage });
  });
});

const dataConfirmationValidation = {
  phone: yup
    .string()
    .required('No. Handphone tidak boleh kosong')
    .min(5, 'Kurang dari 15 digit')
    .phoneFormat('No. Handphone tidak valid'),
  email: yup
    .string()
    .required('Email tidak boleh kosong')
    .emailFormat('Email tidak valid'),
};

export const schemaDataConfirmation = yup
  .object()
  .shape(dataConfirmationValidation);
