import { listedLongformField } from 'constant';

const useCheckApplicationField = () => {
  const getFields = () => {
    const fields = {
      disabledFields: [],
      hiddenFields: [],
    };
    fields.disabledFields = listedLongformField?.disable;
    fields.hiddenFields = listedLongformField?.hide;

    return fields;
  };

  const isFieldDisabled = (name) => {
    const isDisabled = getFields().disabledFields.includes(name);
    return isDisabled;
  };

  const isFieldHidden = (name) => {
    const ishided = getFields().hiddenFields.includes(name);

    return ishided;
  };

  return {
    isFieldDisabled,
    isFieldHidden,
  };
};

export default useCheckApplicationField;
