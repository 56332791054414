import React from 'react';
import Lazyload from 'components/Lazyload';

const PinVerificationLazy = React.lazy(() =>
  import(
    /* webpackChunkName: "webview-activation-pin-verification" */ './PinVerification'
  )
);

const PinVerification = (props) => {
  return (
    <Lazyload component={PinVerificationLazy} animationLoading {...props} />
  );
};

export default PinVerification;