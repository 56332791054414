import React from 'react';
import Lazyload from 'components/Lazyload';

const VerifyPageLazy = React.lazy(() =>
  import(
    /* webpackChunkName: "webview-activation-verify-data" */ './VerifyPage'
  )
);

const VerifyPage = (props) => {
  return (
    <Lazyload component={VerifyPageLazy} animationLoading {...props} />
  );
};

export default VerifyPage;