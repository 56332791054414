import React from 'react';
import PropTypes from 'prop-types';
import Steps from 'components/Steps';
import ApplicationPage from 'components/ApplicationPage';
import { Div, Row, Col } from 'assets/css/styled';

interface Props {
  children: any;
}

const ApplicationLayout: React.FC<Props> = ({ children }) => {
  return (
    <Div>
      <ApplicationPage>
        <Div>
          <Row>
            <Col md='12'>
              <Div paddingBottom='15px' marginTop='30px'>
                {children}
              </Div>
            </Col>
          </Row>
        </Div>
      </ApplicationPage>
    </Div>
  );
};

export default ApplicationLayout;
