import React from 'react';
import loading from 'assets/img/loading.gif';

import { useUserContext } from 'providers/UserProvider';
import useGlobalState from 'actions';


function LoadingOverlay() {
  const { isLoadingOverlay } = useUserContext();
  const [state] = useGlobalState();
  return state.isLoadingOverlayShown || isLoadingOverlay ? (
    <div
      style={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: 9999999999,
        background: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={loading} alt='' style={{ width: '100px', height: '100px' }} />
    </div>
  ) : null
}

export default LoadingOverlay;
