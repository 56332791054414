import React from 'react';
import Lazyload from 'components/Lazyload';
import { Props } from './type';

const DialogInfoLazy = React.lazy(() =>
  import(/* webpackChunkName: "dialog-info-component" */ './DialogInfo')
);

const DialogInfo: React.FC<Props> = (props) => {
  return <Lazyload component={DialogInfoLazy} animationLoading {...props} />;
};

export default DialogInfo;