import styled from '@emotion/styled';
import {
    MIN_WIDTH
} from 'constant';

export interface IBarBackStyled {
    background?: string;
    borderBottom?: string;
  }

// CSS
// STYLED
export const BarBackStyled = styled.nav<IBarBackStyled>`
    ${props => {
        return {
            background: props.background,
            minWidth: MIN_WIDTH,
            borderBottom: props.borderBottom && `1px solid ${props.borderBottom}`,
        }
    }};
    padding: 1.5rem 1.5rem;
    display: flex;
`;