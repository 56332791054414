import React from 'react';
import PropTypes from 'prop-types';
const CermatiTheme = React.lazy(() =>
  import(/* webpackChunkName: "theme-selector-cermati-component" */ '../themes/cermati')
);
const ThemeSelector = ({ children }) => {
  return (
    <>
      <React.Suspense fallback={<div />}>
        <CermatiTheme />
      </React.Suspense>
      {children}
    </>
  );
};

ThemeSelector.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ThemeSelector;
