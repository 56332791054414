import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MAX_WIDTH, MIN_WIDTH } from "constant";

export const Content = styled.div`
height: 100%;
  .layout-wrapper {
    padding-top: 20px;
  }
`;

export const fluid: any = css`
  min-width: 100%;
  max-width: 100%;
`

export const defaultWidth: any = css`
  min-width: ${MIN_WIDTH};
  max-width: ${MAX_WIDTH};
`
