import React, { ReactNode } from 'react';
import { Route, Redirect, useParams } from 'react-router-dom';
import { useUserContext } from '../../providers/UserProvider';
import queryString from 'query-string';
import utils from 'utils';
interface Props {
  component?: ReactNode;
  redirectUrl?: string;
  [key: string]: unknown;
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  redirectUrl,
  ...rest
}) => {
  const { datas } = useUserContext();
  const params: { partner: string } = useParams();
  const secretKey = utils.store.get('secretKey');

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to login page
    <Route
      {...rest}
      render={(props) => {
        if (!datas.token && !secretKey) {
          switch (rest.type) {
            case 'webview':
              return <Redirect to={`/view/login`} />;
            default:
              if (redirectUrl) {
                console.log('tes');
                const path = location.pathname;
                const search = queryString.parse(location.search);

                if (path !== '/') {
                  search['next'] = path;
                }

                return (
                  <Redirect
                    to={{
                      pathname: redirectUrl,
                      search: queryString.stringify(search),
                    }}
                  />
                );
              }
              return <Redirect to={`/${params.partner}/login`} />;
          }
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
