import './styles.scss';

import React, { useEffect, useLayoutEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import CameraPhoto, {
  FACING_MODES,
  IMAGE_TYPES,
} from 'jslib-html5-camera-photo';

import { MIN_WIDTH } from 'constant';
import useGlobalState from 'actions';

import DialogCamera from 'components/Dialog/DialogCamera';

import circle from 'assets/img/circle.png';
import leftArrow from 'assets/img/icon/ic-back.svg';
import selfiePhotoLarge from 'assets/img/background/bg-photo_selfie_large.png';

import { Button, ButtonOutline, Div } from 'assets/css/styled';

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

interface IProps {
  dialogData?: {
    title?: string,
    content?: any
  },
  type?: any,
  name?: string,
  onCancel?: (e?: any) => void,
  onImageTaken?: (e?: any) => void,
  onImageRetry?: (e?: any) => void,
  onImageSubmitted?: (e?: any) => void,
}

const FrontCameraUniversal: React.FC<IProps> = (props) : JSX.Element => {
  const [state, actions] = useGlobalState();
  const height = useWindowSize();
  const [isTaken, setIsTaken] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [cameraPhoto, setCameraPhoto] = useState<any>(() => {});
  const [cameraViewControl, setCameraViewControl] = useState<any>({});
  const [cameraOutputControl, setCameraOutputControl] = useState<{
    src?: string
  }>({});
  const [dialogGuidance, setDialogGuidance] = useState(props?.dialogData);

  let config = {
    sizeFactor: 1,
    imageType: IMAGE_TYPES.JPG,
    imageCompression: 1,
    isImageMirror: false,
  };

  useEffect(() => {
    if (props?.type === 'selfie') {
      setDialogGuidance((prev) => ({
        ...prev,
        ...props.dialogData,
      }));
    }
  }, [props?.dialogData]);

  useEffect(() => {
    if (cameraPhoto?.stream) {
      cameraPhoto.stopCamera();
    }

    if (cameraViewControl) {
      actions.openLoadingOverlay();
    }

    const timeOut = setTimeout(async () => {
      if (state.isSelfiePhotoDialogShown && cameraViewControl && !isRendered) {
        actions.openLoadingOverlay();
        const tempCameraPhoto = await new CameraPhoto(cameraViewControl);
        setCameraPhoto(tempCameraPhoto);

        tempCameraPhoto
          .startCamera(FACING_MODES.USER, {})
          .then(() => {
            setIsRendered(true);
            setTimeout(() => {
              actions.closeLoadingOverlay();
            }, 500);
          })
          .catch((error) => {
            const errorName = error?.name;
            if (errorName === 'NotAllowedError') {
              actions.openNotification(
                true,
                'Mohon aktifkan permintaan akses kamera'
              );
            } else {
              actions.openNotification(
                true,
                'Ada gangguan kamera, silakan coba lagi'
              );
            }
            setTimeout(() => {
              actions.closeNotification();
            }, state.notificationDuration);
            actions.closeLoadingOverlay();
          });
      }
    }, 250);

    return () => clearTimeout(timeOut);
  }, [cameraViewControl]);

  useEffect(() => {
    return () => actions.setState('isSelfiePhotoDialogShown', false);
  }, []);

  return (
    <Dialog
      open={state.isSelfiePhotoDialogShown || false}
      onClose={() => actions.setState('isSelfiePhotoDialogShown', false)}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: '#000',
        },
      }}
    >
      <DialogContent style={{ padding: '0px' }}>
        <div
          className='camera'
          style={{ backgroundColor: '#222222', height: '100vh' }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'fixed',
            }}
          >
            <video
              style={{
                transform: 'scaleX(-1)',
                height: '100%',
                position: 'fixed',
              }}
              ref={(videoCamera: any) => setCameraViewControl(videoCamera)}
              className='cameraViewFront'
              autoPlay
              playsInline
            />
            <img
              src='//:0'
              alt=''
              ref={(cameraOutput: any) => setCameraOutputControl(cameraOutput)}
              className='cameraOutputFront'
              style={{
                display: isTaken ? 'block' : 'none',
                transform: 'scaleX(1)',
              }}
            />
            <div
              style={{
                cursor: 'pointer',
                padding: '16px',
                color: '#ffffff',
                position: 'fixed',
                zIndex: '99991',
              }}
              onClick={() => {
                try {
                  setIsRendered(false);
                  setIsTaken(false);
                  if (cameraPhoto.stream) {
                    cameraPhoto.stopCamera();
                  }
                } catch (error) {
                  if (props.onCancel) props.onCancel(true);
                  actions.setState('isSelfiePhotoDialogShown', false);
                } finally {
                  if (props.onCancel) props.onCancel(true);
                  actions.setState('isSelfiePhotoDialogShown', false);
                }
              }}
            >
              <img src={leftArrow} style={{ marginRight: '20px' }} />
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                Foto {props.name}
              </span>
            </div>
            <div
              className='cameraTriggerContainerFront'
              style={{ zIndex: '99991', position: 'fixed' }}
            >
              {isTaken ? (
                <div
                  style={{
                    display: 'block',
                    width: window.innerWidth,
                    alignItems: 'center',
                    paddingRight: '16px',
                    bottom: '5%',
                    transform: 'translate(0%, -50%)',
                  }}
                >
                  <ButtonOutline
                    borderless='true'
                    style={{ width: '100px' }}
                    className='text-white mx-3'
                    onClick={() => {
                      setIsTaken(false);

                      const tempCameraPhoto = new CameraPhoto(
                        cameraViewControl
                      );

                      setCameraPhoto(tempCameraPhoto);

                      tempCameraPhoto
                        .startCameraMaxResolution(FACING_MODES.USER)
                        .then(() => {})
                        .catch(() => {});

                      setCameraOutputControl({
                        ...cameraOutputControl,
                        src: '//:0',
                      });

                      if (props.onImageRetry) {
                        props.onImageRetry();
                      }
                    }}
                  >
                    Foto Ulang
                  </ButtonOutline>
                  <Button
                    types='primary'
                    style={{ width: '100px' }}
                    className='mx-3'
                    onClick={() => {
                      if (props.onImageSubmitted) {
                        var sp = cameraOutputControl.src && cameraOutputControl.src.split(',');
                        if (sp && sp.length === 2) {
                          if (sp[1] === '') {
                            alert(state.cameraAllowError);
                          } else {
                            try {
                              if (cameraPhoto?.stream) {
                                cameraPhoto.stopCamera();
                                props.onImageSubmitted(cameraOutputControl.src);
                                setIsRendered(false);
                                setIsTaken(false);
                                actions.setState(
                                  'isSelfiePhotoDialogShown',
                                  false
                                );
                              }
                            } catch (error) {
                              actions.setState(
                                'isSelfiePhotoDialogShown',
                                false
                              );
                            }
                          }
                        }
                      }
                    }}
                  >
                    OK
                  </Button>
                </div>
              ) : (
                <img
                  alt='Take Photo'
                  src={circle}
                  style={{
                    cursor: 'pointer',
                    display: 'block',
                    position: 'fixed',
                    left: '50%',
                    bottom: '5%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  onClick={() => {
                    if (cameraPhoto && isRendered) {
                      setIsTaken(true);

                      let imageBase64 = cameraPhoto.getDataUri(config);
                      cameraOutputControl.src = imageBase64;
                      if (props.onImageTaken) {
                        props.onImageTaken(cameraOutputControl.src);
                      }
                    }
                  }}
                />
              )}
            </div>
            {!isTaken && (
              <div
                style={{
                  height: '100vh',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{
                    position: 'absolute',
                    zIndex: '99990',
                    height: height,
                  }}
                  src={selfiePhotoLarge}
                />
              </div>
            )}
          </div>
          <Div minWidth={MIN_WIDTH} position='absolute'>
            {dialogGuidance?.content ? (
              <DialogCamera data={dialogGuidance} />
            ) : (
              ''
            )}
          </Div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default FrontCameraUniversal;
