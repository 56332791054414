import themeJ1 from './Partner/j1';
import themeRentee from './Partner/rentee';
import themeLinkAja from './Partner/linkaja';
import themePaylater from './Partner/paylater';

const theme = {
  themeJ1,
  themeRentee,
  themeLinkAja,
  themePaylater,
};

export default theme;
