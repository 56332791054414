const store = {
  set: (keyObject, value) => {
    if (typeof keyObject === 'object') {
      Object.keys(keyObject).forEach(function (key) {
        sessionStorage.setItem(key, keyObject[key]);
      });
    } else {
      sessionStorage.setItem(keyObject, value);
    }
  },

  setLocal: (keyObject, value) => {
    if (typeof keyObject === 'object') {
      Object.keys(keyObject).forEach(function (key) {
        localStorage.setItem(key, keyObject[key]);
      });
    } else {
      localStorage.setItem(keyObject, value);
    }
  },

  get: (key) => {
    if (
      sessionStorage.getItem(key) == null ||
      sessionStorage.getItem(key) == 'null' ||
      sessionStorage.getItem(key) == undefined ||
      sessionStorage.getItem(key) == 'undefined'
    ) {
      return '';
    }
    return sessionStorage.getItem(key);
  },

  getLocal: (key) => {
    if (
      localStorage.getItem(key) == null ||
      localStorage.getItem(key) == 'null' ||
      localStorage.getItem(key) == undefined ||
      localStorage.getItem(key) == 'undefined'
    ) {
      return '';
    }
    return localStorage.getItem(key);
  },

  getParse: (key) => {
    if (
      sessionStorage.getItem(key) == null ||
      sessionStorage.getItem(key) == 'null' ||
      sessionStorage.getItem(key) == undefined ||
      sessionStorage.getItem(key) == 'undefined'
    ) {
      return '';
    }
    const temp = sessionStorage.getItem(key);
    return JSON.parse(temp);
  },

  removeItem: (key) => {
    if (Array.isArray(key)) {
      return key.forEach((k) => sessionStorage.removeItem(k));
    }
    return sessionStorage.removeItem(key);
  },

  clearAllItem: (except = null) => {
    if (except) {
      if (Array.isArray(except)) {
        const tempExcept = except.map((ex) => sessionStorage.getItem(ex));
        sessionStorage.clear();
        
        return except.forEach((ex, index) => 
          sessionStorage.setItem(ex, tempExcept[index]));

      } else {
        const tempExcept = sessionStorage.getItem(except);
        sessionStorage.clear();

        return sessionStorage.setItem(except, tempExcept);
      }
    } else {
      return sessionStorage.clear();
    }
  },
};

export default store;
