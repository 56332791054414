import { css } from '@emotion/css';

import BackgroundImage from 'assets/img/background/bg-cermati_auth.png';

export const registerCx = css`
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    max-width: 31.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-wrapper {
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5rem;
      .title {
        margin: 0;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        font-weight: bold;
      }

      .subtitle {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }

    .field-group {
      width: 100%;
      &:not(:last-of-type) {
        margin-bottom: 0.75rem;
      }
    }

    .continue-btn {
      width: 10rem;
      padding: 0.75rem 0;

      border-radius: 0.5rem;

      background-color: white;
      color: #00acf0;

      margin-top: 1.25rem;

      &:disabled {
        color: #fff;
        background-color: #e5e5e5;
      }
    }
  }
`;

export const inputCx = css`
  .MuiInputBase-root {
    border: 1px solid white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    color: white;
    &.Mui-error {
      border-color: #db4d3d;
    }

    &::before,
    &::after {
      display: none;
    }

    .icon {
      margin-right: 1rem;
    }

    input {
      &::placeholder {
        color: white;
      }
    }
  }
  border-color: white;
`;
