import React from "react";
import PropTypes from 'prop-types';
import { Route, Redirect, useParams } from "react-router-dom";
import { useUserContext } from "providers/UserProvider";
import utils from "../../utils";

interface Props {
  component?: any;
  redirectUrl?: string;
  [key:string]: any;
}


const PublicRoute: React.FC<Props> = ({ component: Component, redirectUrl, ...rest }) => {
  const { datas } = useUserContext();
  const { partner }: { partner: string } = useParams();
  const pageType = rest.type == 'webview' ? 'view' : utils.store.get('partner') || partner;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const page = urlParams.get('page');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (datas.token) {
          if (rest.type == 'webview') {
            return <Redirect to={`/${pageType}/${page}`} />;
          }

          if (redirectUrl) {
            return <Redirect to={redirectUrl} />;
          }
          return <Redirect to={`/${pageType}`} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRoute;
