import {
  getDropdownList,
  getSettings,
  getLongFormSettings,
  getBanks,
  postAddressInfo,
  postAddressCities,
  postAddressDistricts,
  postAddressSubDistricts,
} from 'services/form';

import {
  submitAxiataLongForm,
  uploadPartnershipImage,
  getPartnershipAddresses,
  getPartnershipDropdowns,
  getPartnershipImages,
  getAxiataTemporaryDataID,
} from 'services/partner/common/partnership';

const useApplicationApiResolver = (partner) => {
  const dropdownResolver = (payload) => {
    if (partner === 'axiata') {
      // call All DropdownAPI
      return;
    }
    return getDropdownList(payload);
  };

  const banksResolver = async (payload) => {
    if (partner === 'axiata') {
      const response = await getPartnershipDropdowns({
        data_selected: 'banks',
      });
      return response?.data;
    }
    return getBanks(payload);
  };

  const provinceResolver = () => {
    return getPartnershipAddresses({ address_type: 'province' });
  };

  const citiesResolver = (payload) => {
    return postAddressCities(payload);
  };

  const districtsResolver = (payload) => {
    return postAddressDistricts(payload);
  };

  const subDistrictsResolver = (payload) => {
    return postAddressSubDistricts(payload);
  };

  const ktpResolver = (payload, appID) => {
    return uploadPartnershipImage(payload, 'ktp_self', appID);
  };

  const selfieResolver = (payload, isCropImage, appID) => {
    if (isCropImage) {
      return uploadPartnershipImage(payload, 'selfie', appID);
    }
    return uploadPartnershipImage(payload, 'selfie', appID);
  };

  const submitLongFormResolver = async (payload, appID) => {
    return submitAxiataLongForm(payload, appID);
  };

  const dataImagesResolver = async () => {
    const response = await getPartnershipImages();
    const filter = response.data.filter(
      (item) => item?.image_status === 0 && item?.image_url_api,
    );
    const convertToObject = filter.reduce(
      (prev, item) => ({ ...prev, [item?.image_type]: item?.image_url_api }),
      {},
    );
    return convertToObject;
  };

  // pending
  const addressInfoResolver = (payload) => {
    return postAddressInfo(payload);
  };

  const settingsResolver = (payload) => {
    if (partner === 'axiata') {
      return;
    }
    return getSettings(payload);
  };

  const longFormSettingsResolver = (payload) => {
    if (partner === 'axiata') {
      return;
    }
    return getLongFormSettings(payload);
  };

  return {
    dropdownResolver,
    settingsResolver,
    longFormSettingsResolver,
    banksResolver,
    addressInfoResolver,
    provinceResolver,
    citiesResolver,
    districtsResolver,
    subDistrictsResolver,
    ktpResolver,
    selfieResolver,
    dataImagesResolver,
    submitLongFormResolver,
    getAxiataTemporaryDataID,
  };
};

export default useApplicationApiResolver;
