import React from 'react';
import { cx } from '@emotion/css';

import Layout from 'components/Layout';

import imgApplicationSuccess from 'assets/img/partner/axiata/positive.png';

import { Div } from 'assets/css/styled';
import {
  fontWeight600,
  h100,
  my3,
  my4,
  positionAbsolute,
  textCenter,
  translateCenter,
} from 'assets/css/stylesFix';
import { width } from 'assets/css/stylesValue';

function ApplicationSuccess() {
  return (
    <Layout
      className={h100}
      layoutContainer={{ height: '100%' }}
      hideBarBack
      hideNavbarMenu
      disableClickLogo
    >
      <Div className={cx(h100)}>
        <Div
          className={cx(
            positionAbsolute,
            translateCenter,
            textCenter,
            width('80%'),
          )}
        >
          <img src={imgApplicationSuccess} />
          <Div className={cx(my4)}>
            <Div fontColor='#00ACF0' className={cx(my3, fontWeight600)}>
              Formulir Berhasil Terkirim
            </Div>
            <Div>
              Harap tunggu hingga proses verifikasi
              <br />
              data kamu selesai, ya!
            </Div>
          </Div>
        </Div>
      </Div>
    </Layout>
  );
}

export default ApplicationSuccess;
