import React from 'react';
import Lazyload from 'components/Lazyload';
import { Props } from './type';

const SelectOptionLazy = React.lazy(() =>
  import(
    /* webpackChunkName: "select-option-component" */ './SelectOption'
  )
);

const SelectOption: React.FC<Props> = (props) => {
  return (
    <Lazyload component={SelectOptionLazy} animationLoading {...props} />
  );
};

export default SelectOption;
