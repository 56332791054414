import axios from 'axios';

import { config } from '../configs';
import services from '../services';
import utils from '../utils';

const commonService = {
  upload: (payload, successCallback, errorCallback) => {
    axios
      .post(config.apiUrl + 'common/upload', payload)
      .then((response) => {
        successCallback(response.data);
      })
      .catch((exception) => {
        services.sentry.handleSentryApiError(exception);
        errorCallback(exception);
      });
  },
  getDropdownData: async (params, successCallback) => {
    axios
      .get('https://api-staging.julo.co.id/api/partner/grab/common/dropdown', {
        headers: {
          Authorization: `Token ${utils.store.get('token')}`,
          'Token-Version': '1.0',
        },
        params,
      })
      .then((response) => {
        successCallback(response.data);
      })
    //   .catch((exception) => {
    //     //services.sentry.handleSentryApiError(exception);
    //     //errorCallback(exception)
    //   });
  },
  getData: async ({ uri, params }, successCallback, errorCallback) => {
    axios
      .get(config.apiUrl + uri, {
        headers: {
          Authorization: `Token ${utils.store.get('token')}`,
          'Token-Version': '1.0',
        },
        params,
      })
      .then((response) => {
        successCallback(response.data);
      })
      .catch((exception) => {
        if (errorCallback) errorCallback(exception);
      });
  },
  postData: async ({ uri, body }, successCallback, errorCallback) => {
    axios
      .post(config.apiUrl + uri, body, {
        headers: {
          Authorization: `Token ${utils.store.get('token')}`,
          'Token-Version': '1.0',
        },
      })
      .then((response) => {
        successCallback(response.data);
      })
      .catch((exception) => {
        if (errorCallback) errorCallback(exception);
      });
  },
  putData: async ({ uri, body }, successCallback, errorCallback) => {
    axios
      .put(config.apiUrl + uri, body, {
        headers: {
          Authorization: `Token ${utils.store.get('token')}`,
          'Token-Version': '1.0',
        },
      })
      .then((response) => {
        successCallback(response.data);
      })
      .catch((exception) => {
        if (errorCallback) errorCallback(exception);
      });
  },

  get: (uri, successCallback, errorCallback) => {
    axios
      .get(config.apiUrl + uri)
      .then((response) => {
        successCallback(response.data);
      })
      .catch((exception) => {
        services.sentry.handleSentryApiError(exception);
        errorCallback(exception);
      });
  },

  getImageDetails: (successCallback, errorCallback) => {
    const AppId = utils.store.get('applicationId');
    let url = `/v1/applications/${AppId}/images/?include_deleted=false`;
    axios
      .get(config.apiUrl + url, {
        headers: {
          Authorization: `Token ${utils.store.get('token')}`,
          'Token-Version': '1.0',
        },
      })
      .then((response) => {
        successCallback(response.data);
      })
      .catch((exception) => {
        if (errorCallback) errorCallback(exception);
      });
  },

  post: (uri, payload, successCallback, errorCallback) => {
    axios
      .post(config.apiUrl + uri, payload)
      .then((response) => {
        successCallback(response.data);
      })
      .catch((exception) => {
        services.sentry.handleSentryApiError(exception);
        errorCallback(exception);
      });
  },
};

export default commonService;
