import React, { useState, useEffect } from 'react';
import { Switch, withRouter, useParams } from 'react-router-dom';

import MerchantFinancing from 'pages/webview/MerchantFinancing';
import Activation from 'pages/webview/Activation';
import utils from 'utils';

import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
import LoginPage from 'pages/webview/Login';
import ErrorPage from 'pages/webview/ErrorPage';

function WebViewRoutes() {
  const { page } = useParams();
  const queryString = window.location.search;
  const paramsURL = utils.store.getParse('params');
  const urlParams = new URLSearchParams(queryString);
  const partnerCategory = urlParams.get('partner_category');
  const [privateComp, setPrivateComp] = useState({
    category: MerchantFinancing,
    page: '',
  });
  const [publicComp, setPublicComp] = useState({});
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    FetchRoutes();
    return () => {
      // unmounted
    };
  }, [isMounted]);

  const FetchRoutes = () => {
    const tempPartnerCategory = partnerCategory || paramsURL?.partner_category;
    if (tempPartnerCategory) {
      switch (tempPartnerCategory) {
        case 'merchant_financing':
          setPrivateComp((prevState) => ({
            ...prevState,
            category: MerchantFinancing,
          }));
          break;
      }
    } else if (page) {
      switch (page) {
        case 'activation':
          setPublicComp((prevState) => ({
            ...prevState,
            category: Activation,
          }));
          break;
      }
    }

    setIsMounted(true);
  };

  return isMounted ? (
    <Switch>
      <PublicRoute
        exact
        type='webview'
        component={LoginPage}
        path={`/view/login`}
      />

      <PublicRoute
        exact
        type='webview'
        component={ErrorPage}
        path={`/view/error`}
      />

      {/* execute if public component category exist */}
      {publicComp?.category ? (
        <>
          <PublicRoute
            exact
            type='webview'
            component={publicComp.category}
            path={`/view/:page/`}
          />
          <PublicRoute
            exact
            type='webview'
            component={publicComp.category}
            path={`/view/:page/:type`}
          />
        </>
      ) : null}

      <PrivateRoute
        exact
        type='webview'
        component={privateComp.category}
        path={`/view/:page/`}
      />
      <PrivateRoute
        exact
        type='webview'
        component={privateComp.category}
        path={`/view/:page/:type`}
      />
    </Switch>
  ) : null;
}

export default withRouter(WebViewRoutes);
