import { POST } from './axios';

import { config } from '../configs';

const HEADERS = {
  'Content-Type': 'application/json',
};

const END_POINT = config.apiUrl;

const login = (payload) => {
  const param = {
    url: `${END_POINT}/pin/web/v1/login/`,
    data: payload,
    headers: HEADERS,
  };
  return POST(param);
};

const register = (payload) => {
  const param = {
    url: `${END_POINT}/pin/web/v1/register/`,
    data: payload,
    headers: HEADERS,
  };
  return POST(param);
};

const preRegister = (payload) => {
  const param = {
    url: `${END_POINT}/pin/v1/preregister-check`,
    data: {
      ...payload,
      app_name: 'web',
    },
    headers: HEADERS,
  };
  return POST(param);
};

const checkPinWeakness = (payload) => {
  const param = {
    url: `${END_POINT}/pin/v1/check-strong-pin/`,
    data: payload,
    headers: HEADERS,
  };
  return POST(param);
};

const resetPassword = (payload) => {
  const param = {
    url: `${END_POINT}/partnership/web/v1/reset-pin`,
    data: payload,
    headers: HEADERS,
  };
  return POST(param);
};

const scrapData = ({ payload, token }) => {
  const param = {
    url: `${END_POINT}/web/v1/scrap-data/`,
    data: payload,
    headers: {
      ...HEADERS,
      Authorization: `Token ${token}`,
    },
  };
  return POST(param);
};

const validateApplication = (payload) => {
  const param = {
    url: `${END_POINT}/partnership/web/v1/validate-application`,
    data: payload,
    headers: HEADERS,
  };
  return POST(param);
};

export {
  login,
  register,
  preRegister,
  checkPinWeakness,
  resetPassword,
  scrapData,
  validateApplication
};
