import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useUserContext } from 'providers/UserProvider';
import { validateToken } from 'services/employee-financing';
import { useQueryParams } from 'utils/RouteHelper';
import store from 'utils/Store';

interface Props {
  children: any;
}

const ValidationWrapper: React.FC<Props> = ({ children }) => {
  const { handleLoadingOverlay, handleNotification } = useUserContext();
  const history = useHistory();
  const query = useQueryParams();
  const token = query?.get('token') || '';
  const storedToken = store.get('token') || '';

  useEffect(() => {
    getTokenValidation();
  }, []);

  const getTokenValidation = async () => {
    handleLoadingOverlay(true);
    try {
      await validateToken(token || storedToken);
      // if no error, then token is valid
      handleLoadingOverlay(false);
    } catch (error: any) {
      const errors = error?.response?.data?.errors ?? {};
      const errorMessage = Object.values(errors)[0];

      handleNotification({
        isOpen: true,
        message: errorMessage,
      });
      // notification will change to go to error page

      history.replace('/ef-pilot/expired');
      handleLoadingOverlay(false);
    }
  };

  return children;
};

export default ValidationWrapper;
