import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { cx } from '@emotion/css';

import { MAX_WIDTH, MIN_WIDTH } from 'constant';

import { Content, fluid, defaultWidth } from './styles';
import { dFlex, flexColumn } from 'assets/css/stylesFix';
import { background, flex, padding } from 'assets/css/stylesValue';
import { Div, Wrapper, Container } from 'assets/css/styled';

import BarBack from 'components/BarBack';
import NavBar from 'components/NavBar';

interface Props {
  barBackTitle?: string;
  barBackbackgroundColor?: string;
  barBackborderBottom?: string;
  barBackColor?: string;
  barBackImage?: string;
  barBackHideImage?: boolean;
  children: any;
  contentBackground?: string;
  disableBarBackRedirect?:boolean;
  disableClickLogo?: boolean;
  fullWidth?:boolean;
  hideBarBack?: boolean;
  hideNavbar?: boolean;
  hideNavbarMenu?: boolean;
  layoutBackground?: string;
  layoutContainer?: any;
  mainWrapperStyles?: any;
  onClickActionsMenu?: (value: any) => void;
  barBackType?: 'primary' | 'secondary';
  barBackActions?: {
    type: string;
    menu: any[];
    menuStyle: any;
  };
}

const Layout: React.FC<Props> = ({
  children,
  disableClickLogo,
  fullWidth,
  hideBarBack = false,
  hideNavbar = false,
  hideNavbarMenu,
  barBackTitle = '',
  barBackColor,
  barBackbackgroundColor,
  barBackborderBottom,
  barBackImage,
  barBackHideImage,
  disableBarBackRedirect,
  layoutContainer,
  layoutBackground,
  contentBackground = '#fff',
  barBackType = 'primary',
  barBackActions,
  onClickActionsMenu,
  mainWrapperStyles,
}) => {
  const theme: any = useTheme();

  const handleClickActions = (value: any) => {
    if (onClickActionsMenu) onClickActionsMenu(value);
  };

  return (
    <Container minWidth={fullWidth ? '100%' : MIN_WIDTH}>
      <div className='layout-component global-wrapper'>
        {!hideNavbar && (
          <NavBar
            disableClickLogo={disableClickLogo}
            hideMenu={hideNavbarMenu}
          />
        )}
        {!hideBarBack && (
          <BarBack
            title={barBackTitle}
            color={barBackColor}
            backgroundColor={barBackbackgroundColor}
            borderBottom={barBackborderBottom}
            disableRedirect={disableBarBackRedirect}
            hideImage={barBackHideImage}
            img={barBackImage}
            withNavbar={!hideNavbar}
            type={barBackType}
            actions={barBackActions}
            handleClickActionsMenu={(e) => handleClickActions(e)}
          />
        )}
        <Wrapper
          height={'100%'}
          minHeight={'100vh'}
          className={cx(
            {
              [padding('125px 0px 0px 0px')]: !hideNavbar && !hideBarBack,
            },
            {
              [padding('64px 0px 0px 0px')]:
                !hideNavbar && hideBarBack && !hideNavbarMenu,
            },
            {
              [padding('58px 0px 0px 0px')]:
                !hideNavbar && hideBarBack && hideNavbarMenu,
            },
            {
              [padding('58px 0px 0px 0px')]: hideNavbar && !hideBarBack,
            },
            {
              [padding('0px')]: hideNavbar && hideBarBack,
            },
            {
              [background(layoutBackground)]: !!layoutBackground,
            },
            {
              [background(theme?.colors?.backgroundColorPrimary)]:
                !layoutBackground,
            }
          )}
          style={{
            display: 'flex',
            position: 'relative',
            ...mainWrapperStyles,
          }}
        >
          <div className={`${dFlex} ${flexColumn} ${flex('1 1 100%')}`}>
            <Wrapper
              className={cx(
                { [fluid]: fullWidth },
                defaultWidth
              )}
              backgroundColor={contentBackground}
              styles={{
                flex: '1 1 100%',
                padding: '0px',
                position: 'relative',
              }}
            >
              <Content>
                <Div
                  position='relative'
                  className='container layout-wrapper'
                  style={layoutContainer}
                  maxWidth={`${fullWidth && '100%'}`}
                >
                  {children}
                </Div>
              </Content>
            </Wrapper>
          </div>
        </Wrapper>
      </div>
    </Container>
  );
};

export default Layout;
