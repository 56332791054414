import * as yup from 'yup';

export const registerScheme = yup.object({
  username: yup
    .string()
    .required('Kolom ini wajib diisi')
    .test(function (value) {
      if (!value) return true;

      return (
        /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/.test(
          value,
        ) ||
        this.createError({
          message: 'Mohon mengisi nik dengan format yang benar',
        })
      );
    }),
  password: yup.string().required('Kolom ini wajib diisi'),
});
