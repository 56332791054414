import React from 'react';
import Lazyload from 'components/Lazyload';

const OtpVerificationLazy = React.lazy(() =>
  import(
    /* webpackChunkName: "webview-activation-otp-verification" */ './OtpVerification'
  )
);

const OtpVerification = (props) => {
  return (
    <Lazyload component={OtpVerificationLazy} animationLoading {...props} />
  );
};

export default OtpVerification;