import { AxiosError } from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, KeyboardEvent, useState } from 'react';
import queryString from 'query-string';

import { Img, Button } from 'assets/css/styled';

import icIdCard from 'assets/img/icon/ic-idcard.svg';
import icLocked from 'assets/img/icon/ic-locked_form.svg';

import Page from 'components/Page';
import utils from 'utils';
import { Input } from 'new-components/elements';
import { config } from 'configs';

import { FormProvider, useForm } from 'react-hook-form';
import { registerScheme } from './constants';
import { inputCx, registerCx } from './styles';
import { RegisterParam } from './types';
import { useUserContext } from 'providers/UserProvider';
import { POST } from 'services/axios';

/**
 * Route: /admin/:type(register|nik)
 */
const Register = () => {
  const { handleNotification } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const isLogin = queryParams.get('next');

  const form = useForm<RegisterParam>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(registerScheme),
  });
  const { handleSubmit, setError } = form;

  const handleInputOnlyNumber = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (
        e.key.length > 1 ||
        ((e.altKey || e.shiftKey || e.ctrlKey || e.metaKey) &&
          e.key.length <= 1)
      )
        return;

      if (/[^a-zA-Z0-9]/.test(e.key) || /^[a-zA-Z]$/.test(e.key))
        return e.preventDefault();
    },
    [],
  );

  async function onSubmit(data: RegisterParam) {
    setIsLoading(true);
    try {
      const res = await POST({
        url: `${config.apiUrl}/merchant-financing/web-portal/register`,
        data,
      });
      utils.store.set('token', res.data.token);
      utils.store.set('nik', data.username);
      let destination = isLogin ? '/application' : '/privacy-policy';
      const search = queryString.parse(location.search);
      const next = search.next;

      if (Boolean(next)) {
        destination = next as string;
        delete search['next'];
      }

      history.replace({
        pathname: destination,
        search: queryString.stringify(search),
      });
    } catch (err) {
      const error = err as AxiosError;

      const statusCode = error.response?.status;

      if (statusCode === 400) {
        const errors = error?.response?.data.errors;

        Object.entries(errors).forEach(([key, value]) => {
          setError(key as keyof RegisterParam, { message: value as string });
        });
      } else {
        handleNotification({
          isOpen: true,
          message: error.response?.data.message || (err as Error).message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (utils.store.get('token')) {
      window.location.replace('/application');
    }
  }, []);

  return (
    <Page className={registerCx}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='title-wrapper'>
            <h6 className='title'>{isLogin ? 'Login' : 'Buat Akun baru'}</h6>
          </div>

          <Input
            disabled={isLoading}
            name='username'
            placeholder='Masukkan NIK'
            className={inputCx}
            onKeyDown={handleInputOnlyNumber}
            InputProps={{
              startAdornment: <Img src={icIdCard} className='icon' />,
            }}
          />
          <Input
            disabled={isLoading}
            name='password'
            type='password'
            placeholder='Masukkan kata sandi'
            className={inputCx}
            InputProps={{
              startAdornment: <Img src={icLocked} className='icon' />,
            }}
          />
          <Button disabled={isLoading} type='submit' className='continue-btn'>
            Lanjutkan
          </Button>
        </form>
      </FormProvider>
    </Page>
  );
};

export default Register;
