const commons = {
  askLocationAccess: (allowedCallback, notAllowedCallback) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (allowedCallback !== null && typeof allowedCallback === 'function') {
          allowedCallback(position);
        }
      },
      (error) => {
        if (
          notAllowedCallback !== null &&
          typeof notAllowedCallback === 'function'
        ) {
          notAllowedCallback(error);
        }
      },
      { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
    );
  },
  isJSON: (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  removeDuplicateObjects: (array, fieldName) => {
    return array.filter(function (item, pos, array) {
      return (
        array
          .map(function (mapItem) {
            return mapItem[fieldName];
          })
          .indexOf(item[fieldName]) === pos
      );
    });
  },
};

export default commons;
