import React, { useEffect } from 'react';

import AppSnackBar from './components/AppSnackBar';
import LoadingOverlay from './components/LoadingOverlay';

import './SnackBar.css';

import Routes from './routes';

import GlobalContextProvider from 'providers/GlobalContextProvider';

import { useQueryParams } from 'utils/RouteHelper';

function App() {
  const query: any = useQueryParams();
  const passbackParams = query.get('passback_params');

  useEffect(() => {
    document.title = 'JULO';
    if (passbackParams) {
      localStorage?.setItem('passback_params', query?.passback_params);
    } else {
      localStorage?.setItem('passback_params', '');
    }
  }, []);

  return (
    <GlobalContextProvider>
      <LoadingOverlay />
      <Routes />
      <AppSnackBar />
    </GlobalContextProvider>
  );
}

export default App;
