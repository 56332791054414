import React from 'react';
import Lazyload from 'components/Lazyload';
import { Props } from './type';

const InputLazy = React.lazy(() =>
  import(
    /* webpackChunkName: "input-component" */ './Input'
  )
);

const Input: React.FC<Props> = (props) => {
  return (
    <Lazyload component={InputLazy} animationLoading {...props} />
  );
};

export default Input;
