import { useUserContext } from 'providers/UserProvider';
import { useHistory } from 'react-router-dom';
import utils from 'utils';
import { useApplicationApiResolver } from '.';

const useApplicationSubmissionResolver = (partner) => {
  const history = useHistory();
  const { submitLongFormResolver } = useApplicationApiResolver(partner);

  const { handleNotification, handleLoadingOverlay } = useUserContext();

  const handleRedirect = () => {
    handleLoadingOverlay(false);

    // clear local storage data after success on submit
    utils.store.removeItem('longFormData');
    utils.store.removeItem('selfieImage');
    utils.store.removeItem('selfieImageCrop');
    utils.store.removeItem('isPhoneVerified');
    utils.store.removeItem('nik');
    utils.store.set('application_success', 'true');

    history.replace({
      pathname: '/application-success',
      state: { submitForm: true },
    });
  };

  const submitForm = async (longFormData, appID) => {
    handleLoadingOverlay(true);
    const localSelfieImageKtp = utils.store.get('selfieImage');
    const localSelfieImageKtpCrop = utils.store.get('selfieImageCrop');

    const payload = {
      fullname: longFormData.fullname,
      ktp: utils.store.get('nik') || longFormData.nik,
      business_category: longFormData.business_category,
      phone_number: longFormData.mobile_phone_1,
      email: utils.store.get('email') || longFormData.email,
      dob: longFormData.dob,
      birth_place: longFormData.birth_place,
      marital_status: longFormData.marital_status,
      gender: longFormData.gender,
      loan_amount: Number(longFormData.loan_amount),
      loan_duration: Number(longFormData.loan_duration),
      address_street_num: longFormData.address_street_num,
      address_provinsi: longFormData.address_provinsi,
      address_kabupaten: longFormData.address_kabupaten,
      address_kecamatan: longFormData.address_kecamatan,
      address_kelurahan: longFormData.address_kelurahan,
      address_kodepos: longFormData.address_kodepos,
      monthly_installment: Number(longFormData.loan_amount),
      final_monthly_installment: Number(longFormData.loan_amount),
    };
    if (!localSelfieImageKtp || !localSelfieImageKtpCrop) {
      handleNotification({
        isOpen: true,
        message: 'Harap melengkapi Foto KTP',
      });
      handleLoadingOverlay(false);
    } else {
      try {
        const response = await submitLongFormResolver(payload, appID);
        if (!response.data.errors.length) handleRedirect();
        handleRedirect(payload);
        handleLoadingOverlay(false);
      } catch (error) {
        handleNotification({
          isOpen: true,
          message:
            error?.response?.data?.errors?.[0] ??
            'Terjadi kesalahan ketika kirim formulir',
        });
        handleLoadingOverlay(false);
      }
    }
  };

  return {
    submitForm,
  };
};

export default useApplicationSubmissionResolver;
