import styled from '@emotion/styled';
import { colsMax, colsMin, handleFontSize } from 'constant';
import {
  StyleTypes,
  ElementTypes,
  ButtonTypes,
  ButtonOutlineTypes,
  CardTypes,
  NavbarTypes,
  WrapperTypes,
} from './types';

type DefaultPropsTypes = (props: StyleTypes) => {};

const defaultProps: DefaultPropsTypes = (props) => {
  return {
    alignItems: props.alignItems,
    background: props.background,
    backgroundColor: props.backgroundColor,
    border: props.border,
    borderBottom: props.borderBottom,
    borderLeft: props.borderLeft,
    borderRadius: props.rounded ? '5px' : props.borderRadius,
    borderRight: props.borderRight,
    borderTop: props.borderTop,
    bottom: props.bottom,
    boxShadow: props.boxShadow,
    color: props.fontColor,
    cursor: props.cursor,
    display: props.display,
    flex: props.flex,
    flexBasis: props.flexBasis,
    flexDirection: props.flexDirection,
    fontWeight: props.fontWeight,
    height: props.height,
    justifyContent: props.justifyContent,
    left: props.left,
    margin: props.margin,
    marginBottom: props.marginBottom,
    marginTop: props.marginTop,
    maxHeight: props.maxHeight,
    maxWidth: props.maxWidth,
    minHeight: props.minHeight,
    minWidth: props.minWidth,
    opacity: props.opacity,
    padding: props.padding,
    paddingRight: props.paddingRight,
    paddingLeft: props.paddingLeft,
    paddingTop: props.paddingTop,
    paddingBottom: props.paddingBottom,
    position: props.position,
    right: props.right,
    textAlign: props.textAlign,
    textDecoration: props.textDecoration,
    top: props.top,
    transform: props.transform,
    transition: props.transition,
    width: props.fluid ? '100%' : props.width,
    alignSelf: props.alignSelf,
    placeContent: props.placeContent,
  };
};

export const Div = styled.div<ElementTypes>`
  ${(props) => props.styles}
  ${(props) => handleFontSize(props?.fontSize)}
    ${(props) => defaultProps(props)}
`;

export const Img = styled.img<ElementTypes>`
  ${(props) => props.styles}
  ${(props) => handleFontSize(props?.fontSize)}
    ${(props) => defaultProps(props)}
`;

export const Label = styled.label<ElementTypes>`
  ${(props) => props.styles}
  ${(props) => handleFontSize(props?.fontSize)}
    ${(props) => defaultProps(props)}
`;

export const Ol = styled.ol<ElementTypes>`
  ${(props) => props.styles}
  ${(props) => handleFontSize(props?.fontSize)}
    ${(props) => defaultProps(props)}
`;

export const Li = styled.li<ElementTypes>`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  ${(props) => props.styles}
  ${(props) => handleFontSize(props?.fontSize)}
    ${(props) => defaultProps(props)}
`;

export const Divider = styled.div<ElementTypes>`
  ${(props) => props.styles}
  ${(props) => defaultProps(props)}
  ${(props) => {
    const theme: any = props.theme;
    const themeColors = theme.colors;
    return {
      background: props.color || themeColors.borderLight,
      margin: props.margin || '1.5rem 0px',
    };
  }};

  width: 100%;
  height: 1px;
`;

export const Button = styled.button<ButtonTypes>`
  ${(props) => {
    const theme = props.theme;
    const type = props.types;

    if (type) {
      switch (type) {
        case 'primary':
          return {
            color: props.color ? props.color : theme?.buttonPrimary?.color,
            background: props.disabled
              ? theme?.buttonPrimary?.disabled
              : props.backgroundColor || theme?.buttonPrimary?.backgroundColor,
          };
        case 'secondary':
          return {
            color: props.color ? props.color : theme?.buttonSecondary?.color,
            background: props.disabled
              ? theme?.buttonSecondary?.disabled
              : props.backgroundColor || theme?.buttonSecondary?.backgroundColor
          };
        case 'grey':
          return {
            color: props.color || theme?.buttonGrey?.color,
            background: props.disabled
              ? theme?.buttonGrey?.disabled
              : props.backgroundColor ||
                theme?.buttonGrey?.backgroundColor,
          };
        case 'gradient':
          return {
            color: props.color ? props.color : theme?.buttonBlue?.color,
            background: props.disabled
              ? theme?.buttonBlue?.disabled
              : props.backgroundColor || theme?.buttonBlue?.gradient,
          };
        default:
          return {
            color: props.color ? props.color : theme?.buttonPrimary?.color,
            background: props.disabled
              ? theme?.buttonPrimary?.disabled
              : props.backgroundColor || theme?.buttonPrimary?.backgroundColor,
          };
      }
    } else {
      return {
        color: props.color ? props.color : theme?.buttonPrimary?.color,
        background: props.disabled
          ? theme?.buttonPrimary?.disabled
          : props.backgroundColor || theme?.buttonPrimary?.backgroundColor
      };
    }
  }};

  ${(props) => {
    return {
      width: props.fluid && '100%',
      border: `1px solid ${
        props.borderColor || props.backgroundColor || 'transparent'
      }`,
      padding: props.padding || '.375rem .75rem',
      textAlign: props.position || 'center',
    };
  }};

  ${(props) => handleFontSize(props?.fontSize)};
  ${(props) =>
    defaultProps({
      ...props,
      borderRadius: props.borderRadius || '.25rem',
    })};

  ${(props) =>
    props.hover && {
      '&:hover': {
        color: props.hoverColor || 'inherit',
        background: props.hoverBackgroundColor || 'inherit',
        borderColor: props.hoverBorderColor || 'inherit',
      },
    }};

  ${(props) =>
    props.backgroundImage && {
      background: `url(${props.backgroundImage})`,
      backgroundSize: props.backgroundSize || 'cover',
    }}

  ${(props) => {
    return {
      display: props.display || 'inline-block',
      backgroundPosition: props.backgroundPosition,
    };
  }}
    
  cursor: pointer;
  font-weight: 400;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.5;
  transition: all 0.25s;
`;

export const ButtonFloating = styled.button`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #00acf0;
  color: #fff;
  border: none;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;

  .floatingButtonText {
    transform: rotate(180deg);
    font-size: 40px;
  }
`;
export const ButtonFloatingSmall = styled.button`
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 40px;
  right: 40px;
  background-color: #00acf0;
  color: #fff;
  border: none;
  border-radius: 20px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;

  .floatingButtonText {
    transform: rotate(180deg);
    font-size: 20px;
  }
`;

export const ButtonOutline = styled.button<ButtonOutlineTypes>`
  cursor: pointer;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.25s;

  ${(props) =>
    props.hover && {
      '&:hover': {
        color: props.hoverColor || 'inherit',
        backgroundColor: props.hoverBackgroundColor || 'inherit',
        borderColor: props.hoverBorderColor || 'inherit',
      },
    }};

  ${(props) => {
    const theme = props.theme;
    const type = props.types;

    if (type) {
      switch (type) {
        case 'primary':
          return {
            color: props.color ? props.color : theme?.buttonOutlinePrimary?.color,
            border: props.borderless
              ? 'none'
              : `1px solid ${theme?.buttonOutlinePrimary?.borderColor}`,
            background: props.disabled
              ? theme?.buttonOutlinePrimary?.disabled
              : props.backgroundColor
              ? props.backgroundColor
              : theme?.buttonOutlinePrimary?.backgroundColor,
          };
        default:
          return {
            color: props.color ? props.color : theme?.colors?.white,
            border: props.borderless
              ? 'none'
              : `1px solid ${props.borderColor || theme?.colors?.white}`,
            background: props.disabled
              ? theme?.buttonOutlinePrimary?.disabled
              : props.background || 'transparent',
          };
      }
    } else {
      return {
        color: props.color ? props.color : theme?.colors?.white,
        border: props.borderless
          ? 'none'
          : `1px solid ${props.borderColor || theme?.colors?.white}`,
        background: props.disabled
          ? theme?.buttonOutlinePrimary?.disabled
          : props.background || 'transparent',
      };
    }
  }};

  ${(props) => handleFontSize(props?.fontSize)};
  ${(props) =>
    defaultProps({
      ...props,
      borderRadius: props.borderRadius || '.25rem',
    })};
`;

export const Card = styled.div<CardTypes>`
  ${(props) => {
    const theme = props.theme;
    return {
      minHeight: props.isOnlyText ? '250px' : props.height && `${props.height}`,
      padding: props.paddingValue || '15px',
      width: props.fluid ? '100%' : props.width && props.width,
      height: props.height && `${props.height}`,
      borderRadius: props.rounded ? '5px' : '0px',
      backgroundColor: props.backgroundColor || theme?.colors?.white,
      boxShadow: props.boxShadow || theme?.cardPrimary?.boxShadow,
    };
  }};

  ${(props) => props.styles}
  ${(props) => handleFontSize(props?.fontSize)};
  ${(props) => defaultProps(props)};

  ${(props) =>
    props.backgroundImage && {
      background: `url(${props.backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}
`;

export const NavBar = styled.nav<NavbarTypes>`
  ${(props) => {
    const theme = props.theme;
    return {
      background: theme?.navbar?.backgroundColor,
      transform: props.expand ? 'translateY(0)' : 'translateY(-100%)',
    };
  }};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 16dp 10dp;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  ${(props) =>
    props.maxWidth && {
      maxWidth: props.maxWidth,
    }}
`;

export const Main = styled.div`
  background-color: #f7f7f7;
  height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
`;

export const Container = styled.div<ElementTypes>`
  background-color: #f7f7f7;
  ${(props) => props.styles}
  ${(props) => handleFontSize(props?.fontSize)}
    ${(props) => defaultProps(props)}
`;

export const Wrapper = styled.div<WrapperTypes>`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  min-width: ${(props) => props.minWidth && `${props.minWidth}px`};
  position: relative;
  transition: all 0.3s;
  width: 100%;

  ${(props) =>
    props.background && {
      background: props.background,
    }}

  ${(props) =>
    props.backgroundColor && {
      backgroundColor: props.backgroundColor,
    }}

    ${(props) =>
    props.flexWrap && {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: props.alignHorizontal,
      alignItems: props.alignVertical,
    }}

    ${(props) =>
    props.maxWidth && {
      maxWidth: `${props.maxWidth}px`,
    }}

    ${(props) => ({
    height: props?.height,
    maxHeight: props?.maxHeight,
    minHeight: props?.minHeight,
    overflowX: props?.overflowX,
  })}

    ${(props) => props.styles}
    ${(props) => handleFontSize(props?.fontSize)};
  ${(props) => defaultProps(props)};
`;

export const Row = styled.div<ElementTypes>`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  ${(props) => handleFontSize(props?.fontSize)};
  ${(props) => defaultProps(props)};
`;

interface ColTypes extends ElementTypes {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  hiddenXs?: boolean;
  hiddenSm?: boolean;

}

export const Col = styled.div<ColTypes>`
${(props) => props.styles}

  min-height: 1px;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;

  ${(props) => handleFontSize(props?.fontSize)};
  ${(props) => {
    return {
      ...defaultProps(props),
      height: props.customHeight || props.height && `${props.height}%`,
    };
  }}

  ${colsMax('small')} {
    ${(props) => {
      const parseValueSmall = props?.xs?.toString();
      switch (parseValueSmall) {
        case '12':
          return {
            flex: '0 0 100%',
            maxWidth: '100%',
          };
        case '11':
          return {
            flex: '0 0 91.666667%',
            maxWidth: '0 0 91.666667%',
          };
        case '10':
          return {
            flex: '0 0 83.333333%',
            maxWidth: '83.333333%',
          };
        case '9':
          return {
            flex: '0 0 75%',
            maxWidth: '75%',
          };
        case '8':
          return {
            flex: '0 0 66.666667%',
            maxWidth: '66.666667%',
          };
        case '7':
          return {
            flex: '0 0 58.333333%',
            maxWidth: '58.333333%',
          };
        case '6':
          return {
            flex: '0 0 50%',
            maxWidth: '50%',
          };
        case '5':
          return {
            flex: '0 0 41.666667%',
            maxWidth: '41.666667%',
          };
        case '4':
          return {
            flex: '0 0 33.33333%',
            maxWidth: '33.33333%',
          };
        case '3':
          return {
            flex: '0 0 25%',
            maxWidth: '25%',
          };
        case '2':
          return {
            flex: '0 0 16.666667%',
            maxWidth: '16.666667%',
          };
        case '1':
          return {
            flex: '0 0 8.333333%',
            maxWidth: '8.333333%',
          };
        default:
          break;
      }
    }}
  }

  ${colsMin('small')} {
    ${(props) => {
      const parseValueSmall = props?.xs?.toString();
      switch (parseValueSmall) {
        case '12':
          return {
            flex: '0 0 100%',
            maxWidth: '100%',
          };
        case '11':
          return {
            flex: '0 0 91.666667%',
            maxWidth: '0 0 91.666667%',
          };
        case '10':
          return {
            flex: '0 0 83.333333%',
            maxWidth: '83.333333%',
          };
        case '9':
          return {
            flex: '0 0 75%',
            maxWidth: '75%',
          };
        case '8':
          return {
            flex: '0 0 66.666667%',
            maxWidth: '66.666667%',
          };
        case '7':
          return {
            flex: '0 0 58.333333%',
            maxWidth: '58.333333%',
          };
        case '6':
          return {
            flex: '0 0 50%',
            maxWidth: '50%',
          };
        case '5':
          return {
            flex: '0 0 41.666667%',
            maxWidth: '41.666667%',
          };
        case '4':
          return {
            flex: '0 0 33.33333%',
            maxWidth: '33.33333%',
          };
        case '3':
          return {
            flex: '0 0 25%',
            maxWidth: '25%',
          };
        case '2':
          return {
            flex: '0 0 16.666667%',
            maxWidth: '16.666667%',
          };
        case '1':
          return {
            flex: '0 0 8.333333%',
            maxWidth: '8.333333%',
          };
        default:
          break;
      }
    }}
  }
  ${colsMin('medium')} {
    ${(props) => {
      const parseValueMedium = props?.md?.toString();
      switch (parseValueMedium) {
        case '12':
          return {
            flex: '0 0 100%',
            maxWidth: '100%',
          };
        case '11':
          return {
            flex: '0 0 91.666667%',
            maxWidth: '0 0 91.666667%',
          };
        case '10':
          return {
            flex: '0 0 83.333333%',
            maxWidth: '83.333333%',
          };
        case '9':
          return {
            flex: '0 0 75%',
            maxWidth: '75%',
          };
        case '8':
          return {
            flex: '0 0 66.666667%',
            maxWidth: '66.666667%',
          };
        case '7':
          return {
            flex: '0 0 58.333333%',
            maxWidth: '58.333333%',
          };
        case '6':
          return {
            flex: '0 0 50%',
            maxWidth: '50%',
          };
        case '5':
          return {
            flex: '0 0 41.666667%',
            maxWidth: '41.666667%',
          };
        case '4':
          return {
            flex: '0 0 33.33333%',
            maxWidth: '33.33333%',
          };
        case '3':
          return {
            flex: '0 0 25%',
            maxWidth: '25%',
          };
        case '2':
          return {
            flex: '0 0 16.666667%',
            maxWidth: '16.666667%',
          };
        case '1':
          return {
            flex: '0 0 8.333333%',
            maxWidth: '8.333333%',
          };
        default:
          break;
      }
    }}
  }
  ${colsMin('large')} {
    ${(props) => {
      const parseValueLarge = props?.lg?.toString();
      switch (parseValueLarge) {
        case '12':
          return {
            flex: '0 0 100%',
            maxWidth: '100%',
          };
        case '11':
          return {
            flex: '0 0 91.666667%',
            maxWidth: '0 0 91.666667%',
          };
        case '10':
          return {
            flex: '0 0 83.333333%',
            maxWidth: '83.333333%',
          };
        case '9':
          return {
            flex: '0 0 75%',
            maxWidth: '75%',
          };
        case '8':
          return {
            flex: '0 0 66.666667%',
            maxWidth: '66.666667%',
          };
        case '7':
          return {
            flex: '0 0 58.333333%',
            maxWidth: '58.333333%',
          };
        case '6':
          return {
            flex: '0 0 50%',
            maxWidth: '50%',
          };
        case '5':
          return {
            flex: '0 0 41.666667%',
            maxWidth: '41.666667%',
          };
        case '4':
          return {
            flex: '0 0 33.33333%',
            maxWidth: '33.33333%',
          };
        case '3':
          return {
            flex: '0 0 25%',
            maxWidth: '25%',
          };
        case '2':
          return {
            flex: '0 0 16.666667%',
            maxWidth: '16.666667%',
          };
        case '1':
          return {
            flex: '0 0 8.333333%',
            maxWidth: '8.333333%',
          };
        default:
          break;
      }
    }}
  }
  ${colsMin('extraLarge')} {
    ${(props) => {
      const parseValueExtraLarge = props?.xl?.toString();
      switch (parseValueExtraLarge) {
        case '12':
          return {
            flex: '0 0 100%',
            maxWidth: '100%',
          };
        case '11':
          return {
            flex: '0 0 91.666667%',
            maxWidth: '0 0 91.666667%',
          };
        case '10':
          return {
            flex: '0 0 83.333333%',
            maxWidth: '83.333333%',
          };
        case '9':
          return {
            flex: '0 0 75%',
            maxWidth: '75%',
          };
        case '8':
          return {
            flex: '0 0 66.666667%',
            maxWidth: '66.666667%',
          };
        case '7':
          return {
            flex: '0 0 58.333333%',
            maxWidth: '58.333333%',
          };
        case '6':
          return {
            flex: '0 0 50%',
            maxWidth: '50%',
          };
        case '5':
          return {
            flex: '0 0 41.666667%',
            maxWidth: '41.666667%',
          };
        case '4':
          return {
            flex: '0 0 33.33333%',
            maxWidth: '33.33333%',
          };
        case '3':
          return {
            flex: '0 0 25%',
            maxWidth: '25%',
          };
        case '2':
          return {
            flex: '0 0 16.666667%',
            maxWidth: '16.666667%',
          };
        case '1':
          return {
            flex: '0 0 8.333333%',
            maxWidth: '8.333333%',
          };
        default:
          break;
      }
    }}
  } ;
`;
