import { createContext, useCallback, useContext, useState } from 'react';

import _noop from '@julofinance/web-helpers/dist/fn/noop';

import type {
  SnackbarContextProps,
  SnackbarOptions,
  SnackbarProviderProps,
} from './types';
import Snackbar from './Snackbar';

const SnackbarContext = createContext<SnackbarContextProps>({
  isOpen: false,
  onClose: _noop,
  onOpen: _noop,
});

const SnackbarProvider = (props: SnackbarProviderProps) => {
  const { children } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState<SnackbarOptions>();

  const handleOpen = useCallback((snackbarOptions?: SnackbarOptions) => {
    setIsOpen(true);
    setSnackbarOptions(snackbarOptions);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <SnackbarContext.Provider
      value={{ isOpen, onOpen: handleOpen, onClose: handleClose }}
    >
      {children}
      <Snackbar open={isOpen} onClose={handleClose} {...snackbarOptions} />
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context)
    throw new Error('useSnackbar muse be used within SnackbarProvider');

  return context;
};

export { SnackbarProvider, useSnackbar };
