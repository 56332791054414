import React from 'react';
import Lazyload from 'components/Lazyload';

const ApplicationPageLazy = React.lazy(() =>
  import(
    /* webpackChunkName: "application-page-component" */ './ApplicationPage'
  )
);

const ApplicationPage = (props: any) => {
  return (
    <Lazyload component={ApplicationPageLazy} animationLoading {...props} />
  );
};

export default ApplicationPage;