import common from "./common"

import sentry from "./sentry"

const services = {};

services.common = common;

services.sentry = sentry;

export default services;
