import React from 'react';
import Lazyload from 'components/Lazyload';

const InputPinLazy = React.lazy(() =>
  import(/* webpackChunkName: "common-input-pin-page" */ './InputPin')
);

const InputPin = (props) => {
  return <Lazyload component={InputPinLazy} animationLoading {...props} />;
};

export default InputPin;
