import NumberFormat from 'react-number-format';
import React from 'react';

interface Props {
  inputRef?: any;
  onChange: (value: any)=> void;
  name?: string;
}

const NumberFormatCustom: React.FC<Props> = (props): JSX.Element => {
  const { inputRef, onChange, ...other } = props;

  function currencyFormatter(val?: any) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format={currencyFormatter}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowLeadingZeros={false}
      pattern='[0-9],*'
    />
  );
}

export default NumberFormatCustom;
