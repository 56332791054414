import iconBackWhite from 'assets/img/icon/ic-arrow_left_white.svg';
import iconBackBlue from 'assets/img/icon/ic-arrow_left_blue.svg';

const themeLinkAja = {
  colors: {
    primary: '#1ea7e9',
    white: '#fff',
    link: '#00acf0',
    blue: '#00acf0',
    blueLight: '#f3fcff',
    backgroundColorPrimary: '#f7f7f7',
    backgroundColorSecondary: '#fffbf5',
    backgroundColorPrimaryGradient: 'linear-gradient(to bottom, #fff, #fff3e5)',
    backgroundColorBlueGradient:
      'linear-gradient(to left top, #00acf0, #1f88d1)',
    black: '#000000',
    borderLight: '#e0e0e0',
    borderLight2: 'rgba(94, 94, 94, 0.5)',
    boxShadow: '0 3px 6px 0 #e6e6e6',
    greyLight: '#e0e0e0',
    greyDark: '#5e5e5e',
    backdropCamera: 'rgb(34, 34, 34)',
  },
  text: {
    primary: '#5e5e5e',
    blue: '#00acf0',
    danger: '#ff8a8a',
    success: '#5dc096',
    greyLight: '#727272',
  },
  navbar: {
    color: '#fff',
    backgroundColor: '#1ea7e9',
    borderColor: '#1ea7e9',
  },
  helperBar: {
    color: '#fff',
    backgroundColor: '#11074e',
    borderColor: '#e0e0e0',
    iconBack: iconBackWhite,
  },
  helperBarPrimary: {
    color: '#fff',
    backgroundColor: '#1ea7e9',
    // backgroundColor: 'linear-gradient(to right, #1ea7e9 , #13637C)',
    borderColor: '#e0e0e0',
    iconBack: iconBackWhite,
  },
  helperBarSecondary: {
    color: '#5e5e5e',
    backgroundColor: '#ffffff',
    borderColor: '#e0e0e0',
    iconBack: iconBackBlue,
  },
  buttonPrimary: {
    disabled: '#e5e5e5',
    color: '#fff',
    backgroundColor: '#1ea7e9',
    borderColor: '#1ea7e9',
  },
  buttonSecondary: {
    disabled: '#e5e5e5',
    color: '#f4991d',
    backgroundColor: '#fff4e7',
    borderColor: '#f4991d',
  },
  buttonGrey: {
      disabled: '#e0e0e0',
      color: '#5e5e5e',
      backgroundColor: '#e0e0e0',
      borderColor: '#e0e0e0'
  },
  buttonOutlinePrimary: {
    disabled: '#fff',
    backgroundColor: '#fff',
    borderColor: '#1ea7e9',
  },

  buttonBlue: {
    disabled: '#e0e0e0',
    color: '#fff',
    backgroundColor: '#00acf0',
    borderColor: '#00acf0',
    gradient: 'linear-gradient(to top, #00acf0, #77d8ff)',
  },
  cardPrimary: {
    disabled: '#fff',
    color: '#fff',
    backgroundColor: '#f4991d',
    borderColor: '#f4991d',
    boxShadow: '0 3px 6px 0 #e6e6e6',
  },
  carousel: {
    indicator: {
      primary: '#f4991d',
      secondary: 'rgba(244, 153, 29, 0.5)',
    },
  },
  navbarApplication: {
    backgroundColor: '#11074e',
  },
};

export default themeLinkAja;
