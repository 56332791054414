import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import utils from 'utils';

const AxiataWildCartRoute = () => {
  const history = useHistory();

  useEffect(() => {
    if (utils.store.get('token')) {
      history.replace('/application');
    } else {
      history.replace('/register');
    }
  }, []);

  return null;
};

export default AxiataWildCartRoute;
