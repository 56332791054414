import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import themeLinkAja from 'themes/Partner/linkaja';

import faq from 'assets/img/icon/ic-faq.svg';
import ojk from 'assets/img/OJK.svg';

import Layout from 'components/Layout';

import { Button, ButtonFloatingSmall, Div, Img } from 'assets/css/styled';
import { borderX, borderY, text } from 'assets/css/stylesValue';

import { useUserContext } from 'providers/UserProvider';
import { termsPrivacy } from 'services/form';
import utils from 'utils';
import Dialog from 'components/Dialog';

const PRIVACY_STYLE = `
    <style>
        body {
            font-size: 12px;
            color: #5E5E5E;
            line-height: 1.5;
            font-family: sans-serif;
            letter-spacing: 0.2px;
        }
    </style>
`;

const TnC = () => {
  const themeText = themeLinkAja?.text;
  const bottomPage = useRef(null);
  const history = useHistory();
  const iframe = useRef(null);
  const [heightIframe, setHeightIframe] = useState(9999);
  const [htmlContent, setHtmlContent] = useState(null);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const { handleLoadingOverlay, handleNotification } = useUserContext();

  const fetchPrivacyData = async () => {
    try {
      const response = await termsPrivacy();
      setHeightIframe(
        iframe?.current?.contentDocument?.activeElement?.clientHeight + 40,
      );

      setHtmlContent(response);
    } catch (error) {
      if (error) {
        handleLoadingOverlay(false);
        handleNotification({
          isOpen: true,
          message:
            error?.response?.data?.errors?.length > 0
              ? error?.response?.data?.errors[0]
              : error?.response?.message,
        });
      }
    }
  };

  const handleAction = async (value) => {
    if (value) {
      history.replace('/application');
    } else {
      utils.store.clearAllItem();
      history.replace('/register');
    }
  };

  useEffect(() => {
    fetchPrivacyData();
  }, []);

  if (!htmlContent) {
    return null;
  }

  return (
    <Layout hideBarBack>
      <Div textAlign='center'>
        <Img width='200px' src={faq} alt='Syarat dan Ketentuan' />
        <h4 className='text-blue text-center'>Privasi Pengguna</h4>
      </Div>
      <Div className={text({ size: 16, color: themeText?.primary })}>
        <iframe
          onLoad={() => {
            setHeightIframe(
              iframe?.current?.contentDocument?.activeElement?.clientHeight -
                100,
            );
            iframe.current.contentDocument.head.innerHTML =
              iframe?.current?.contentDocument?.head?.innerHTML + PRIVACY_STYLE;
          }}
          ref={iframe}
          srcDoc={htmlContent.preface}
          width='100%'
          height={`${heightIframe}px`}
          className='border-0'
        />
      </Div>
      <Div className={text({ size: 16, color: themeText?.primary })}>
        Baca lebih lengkap di
        <span
          className='mx-1 text-blue cursor-pointer'
          onClick={() => setShowPrivacyPolicy(true)}
        >
          Kebijakan Privasi
        </span>
        JULO
      </Div>
      <Div>
        <Div className='bordered-bottom-light py-5'>
          <button
            onClick={() => handleAction(true)}
            className='btn btn-blue w-100 py-3 mb-2'
          >
            Setuju
          </button>
          <button
            onClick={() => handleAction(false)}
            className='btn btn-secondary w-100 py-3'
          >
            Tidak Setuju
          </button>
        </Div>
        <Div ref={bottomPage} className='w-100 text-center py-3'>
          <span className='d-block text-sm mb-2'>Berizin dan diawasi oleh</span>
          <Img src={ojk} alt='' />
        </Div>
      </Div>
      <ButtonFloatingSmall
        onClick={() => {
          bottomPage.current.scrollIntoView({
            behavior: 'smooth',
          });
        }}
      >
        <Div className='floatingButtonText'>^</Div>
      </ButtonFloatingSmall>
      <Dialog show={showPrivacyPolicy} getShow={setShowPrivacyPolicy} fluid>
        <Div className='h-100 d-flex flex-column'>
          <h3 className='w-100 font-weight-bold text-center text-blue my-3'>
            KEBIJAKAN PRIVASI
          </h3>

          <iframe
            srcDoc={htmlContent.text.replace('\n', '')}
            width='100%'
            height='100%'
            className={`${borderX('0px solid #ccc')} ${borderY(
              '1px solid #ccc',
            )}`}
          />

          <Button
            onClick={() => setShowPrivacyPolicy(false)}
            className='btn btn-outline text-blue w-100 py-3'
          >
            Tutup
          </Button>
        </Div>
      </Dialog>
    </Layout>
  );
};

export default TnC;
