import React from 'react';
import Lazyload from 'components/Lazyload';

const VerifyDataLazy = React.lazy(() =>
  import(
    /* webpackChunkName: "webview-activation-verify-data" */ './VerifyData'
  )
);

const VerifyData = (props) => {
  return (
    <Lazyload component={VerifyDataLazy} animationLoading {...props} />
  );
};

export default VerifyData;