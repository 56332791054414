import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import utils from 'utils';

export default (OriginalComponent: (...args: unknown[]) => JSX.Element) => {
  const MixedComponent = (props: Record<string, unknown>) => {
    const history = useHistory();
    const location = useLocation();
    const token = utils.store.get('token');

    useEffect(() => {
      if (!token) {
        const path = location.pathname;
        const search = queryString.parse(location.search);

        if (path !== '/') {
          search['next'] = path;
        }

        return history.replace({
          pathname: '/register',
          search: queryString.stringify(search),
        });
      }
    }, []);

    return token ? <OriginalComponent {...props} /> : null;
  };

  return MixedComponent;
};
