import React, { useState, useEffect } from 'react';
import { cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { useUserContext } from 'providers/UserProvider';
import { useHistory, useLocation } from 'react-router-dom';
import utils from 'utils';

import {
  getSphpForAxiata,
  signLoan,
} from 'services/partner/common/partnership';

import { Card, Div, Img, Wrapper, Button } from 'assets/css/styled';
import { dBlock, mb2, mb4 } from 'assets/css/stylesFix';
import { text } from 'assets/css/stylesValue';

import ojk from 'assets/img/OJK.svg';

import Layout from 'components/Layout';

/**
 * Route: /linkaja/sphp
 */
const SPHP = () => {
  const { replace } = useHistory();
  const { search } = useLocation();
  const theme = useTheme();
  const themeColors = theme?.colors;
  const themeCardPrimary = theme?.cardPrimary;
  const { handleNotification, handleLoadingOverlay } = useUserContext();
  const [htmlContent, setHtmlContent] = useState('');
  const queryParams = new URLSearchParams(search);

  const loanXid = queryParams.get('loan_xid') || utils.store.get('loanXid');

  const getSphpContent = async (loanXid) => {
    handleLoadingOverlay(true);
    handleNotification({ isOpen: false });

    try {
      const response = await getSphpForAxiata(loanXid);
      return setHtmlContent(response.data);
    } catch (error) {
      handleNotification({
        isOpen: true,
        message: error?.response?.data?.errors?.[0],
      });
    } finally {
      handleLoadingOverlay(false);
    }
  };

  const handleSignDocument = async () => {
    handleLoadingOverlay(true);
    handleNotification({ isOpen: false });
    try {
      await signLoan(loanXid);
      replace('/sphp-success');
    } catch (error) {
      handleNotification({
        isOpen: true,
        message: error?.response?.data?.errors?.[0],
      });
    } finally {
      handleLoadingOverlay(false);
    }
  };

  useEffect(() => {
    getSphpContent(loanXid);
  }, []);

  return (
    <Layout
      barBackType=''
      barBackTitle='Surat Perjanjian'
      contentBackground={themeColors?.backgroundColorPrimary}
    >
      <Wrapper padding='0px'>
        <Card
          rounded
          boxShadow={themeCardPrimary?.boxShadow}
          className={cx(mb4)}
        >
          {htmlContent && (
            <>
              <div
                style={{ padding: '0 20px', margin: '0 0 20px 0' }}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
              <Button type='submit' fluid onClick={handleSignDocument}>
                Setuju
              </Button>
            </>
          )}
        </Card>
        <Div
          className={cx(
            text({
              align: 'center',
            }),
            mb2,
          )}
        >
          <span className={cx(text({ size: 12 }), dBlock, mb2)}>
            Berizin dan diawasi oleh
          </span>
          <Img src={ojk} alt='ojk' />
        </Div>
      </Wrapper>
    </Layout>
  );
};

export default SPHP;
