import React from 'react';
import globalHook from 'use-global-hook';
import utils from '../utils';

const initialState = {
  count: 0,
  isLoadingOverlayShown: false,
  isPhotoDialogShown: false,
  notificationDuration: 10000,
  webVersion: '0.0.1',
  errorDivPaddingTop: '10px',
  cameraAllowError:
    'Untuk melanjutkan proses pengajukan, mohon izinkan penggunaan kamera pada perangkat Anda.',
  jobTypes: [
    'pengusaha',
    'staf rumah tangga',
    'freelance',
    'pegawai swasta',
    'pegawai negeri',
  ],
  provinces: [],
  regencies: [],
  villages: [],
  subDistrict: [],
  invalidPhoneError1Flg: true,
  invalidPhoneError2Flg: true,
  invalidDataFlg: true,
  emptyFieldError: 'Field tidak boleh kosong',
  doubleSpaceError: 'Tidak boleh double spasi',
  symbolOrDigitOnlyError: 'Mohon gunakan nama asli',
  minimumBirthOfPlaceError: 'Data tempat lahir kurang dari 3 karakter',
  minimalCharError: 'Minimal 3 karakter',
  symbolOnlyError: 'Mohon isi alamat dengan huruf yang benar',
  mobileFieldError1: 'Mohon diisi dengan format 08xxxxx',
  mobileFieldMinLenError: 'Minimal 10 digit',
  mobileFieldError2:
    'Mohon diisi dengan format 021xxxxx atau dengan nomor daerah lainnya',
  mobileFieldError3:
    'Maaf, nomor yang anda masukkan tidak valid. Mohon masukkan nomor lainnya.',
  mobileFieldMinLenError2: 'Minimal 9 digit',
  dateFieldNumberError: 'Mohon masukkan tanggal yang valid. Contoh 1-31',
  txtWithDigitError: 'Referral harus angka atau huruf',
  sameMobileError: 'Nomor telepon harus berbeda',
  txtMinValue: 3,
  txtPattern: /^[a-zA-Z0-9. \-/,]*$/,
  symbolPattern: /^[^a-zA-Z0-9]+$/,
  doubleSpacePattern: /^.*\s{2,}.*$/,
  txtWithDigitPattern: /^[a-zA-Z0-9]*$/,
  firstLetterDigitPattern: /^[1-9]/,
  mobilePattern1: /^08/,
  mobilePattern2: /^021/,
  defaultDate: '1990-01-01',
  currentField: '',
  isFormFilled: true,
  isFormFilledReview: false,
  isReadTerm: false,
  otp_resend_time: 60,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  ktpLoaded: false,
  selfieLoaded: false,
};

const actions = {
  setState: (store, name, value) => {
    store.setState({ [name]: value });
  },

  setStates: (store, object) => {
    store.setState(object);
  },
  renderError: (store, errorState, errorTxt, paddingTop = '0px') => {
    if (errorState) {
      return (
        <div
          className='error-txt'
          style={{ display: 'inline', paddingTop: paddingTop }}
        >
          {errorTxt}
        </div>
      );
    } else {
      return <div style={{ display: 'none' }}>{errorTxt}</div>;
    }
  },
  openNotification: (store, msgState=false, message, severity = false) => {
    store.setState({
      isNotificationOpened: msgState,
      notificationMessage: message,
      notificationSeverity: severity,
    });
  },
  closeNotification: (store, severity = false) => {
    store.setState({
      isNotificationOpened: false,
      notificationMessage: '',
      notificationSeverity: severity,
    });
  },
  openLoadingOverlay: (store, message = '') => {
    store.setState({
      isLoadingOverlayShown: true,
      loadingOverlayMessage: message,
    });
  },

  closeLoadingOverlay: (store) => {
    store.setState({
      isLoadingOverlayShown: false,
      loadingOverlayMessage: '',
    });
  },

  convertDataURLtoFile: (store, dataurl, filename) => {
    if (dataurl) {
      var sp = dataurl.split(',');
      if (sp.length === 2) {
        if (sp[1] === '') {
          return '';
        } else {
          var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          return new File([u8arr], filename, { type: mime });
        }
      } else {
        return '';
      }
    }
  },
  filterAddress: (store, type, value) => {
    let nwSubDistricts = utils.store.get('nwSubDistrict');
    if (nwSubDistricts) {
      let regencies = [],
        villages = [],
        subDistrict = [],
        postalCode = [];
      nwSubDistricts = JSON.parse(nwSubDistricts);
      let allSubDistricts = nwSubDistricts.map((x) => {
        const arr = x.split(',');
        return {
          postalCode: arr[0],
          subDistrict: arr[1],
          village: arr[2],
          regency: arr[3],
          province: arr[4],
        };
      });
      if (type === 'Kabupaten') {
        regencies = [
          ...new Set(
            allSubDistricts
              .filter((x) => x.province === value)
              .map((x) => x.regency)
          ),
        ];
        store.setState({ regencies: regencies });
      }
      if (type === 'Kecamatan') {
        villages = [
          ...new Set(
            allSubDistricts
              .filter((x) => x.regency === value)
              .map((x) => x.village)
          ),
        ];
        store.setState({ villages: villages });
      }
      if (type === 'Kelurahan') {
        subDistrict = [
          ...new Set(
            allSubDistricts
              .filter((x) => x.village === value)
              .map((x) => x.subDistrict)
          ),
        ];
        store.setState({ subDistrict: subDistrict });
      }
      if (type === 'Kodepos') {
        postalCode = [
          ...new Set(
            allSubDistricts
              .filter((x) => x.subDistrict === value)
              .map((x) => x.postalCode)
          ),
        ];
        if (postalCode.length > 0) {
          store.setState({ postalCode: postalCode[0] });
          store.setState({ addressKodepos: postalCode[0] });
          utils.store.set('addressKodepos', postalCode[0]);
          utils.store.set('addressKodepos_', postalCode[0]);
        } else {
          store.setState({ postalCode: '' });
          store.setState({ addressKodepos: '' });
          utils.store.set('addressKodepos', '');
          utils.store.set('addressKodepos_', '');
        }
      }
    }
  },
  filterJobs: (store, type, value) => {
    let jobs = utils.store.get('jobs');
    if (jobs) {
      jobs = JSON.parse(jobs);
      let jobIndustries = [],
        professions = [];
      let allJobs = jobs.map((x) => {
        const arr = x.split(',');
        const checkBpk = jobIndustries.indexOf(arr[0]);
        if (checkBpk === -1) {
          jobIndustries.push(arr[0]);
        }
        return {
          jobIndustries: arr[0],
          professions: arr[1],
        };
      });
      if (jobIndustries.length > 0) {
        jobIndustries = jobIndustries.sort();
      }
      store.setState({ jobIndustries: jobIndustries });
      if (type === 'profession') {
        professions = [
          ...new Set(
            allJobs
              .filter((x) => x.jobIndustries === value)
              .map((x) => x.professions)
          ),
        ];
        if (professions.length > 0) {
          professions = professions.sort();
        }
        store.setState({ professions: professions });
      }
    } else {
      store.setState({ professions: '' });
      store.setState({ jobIndustries: '' });
    }
  },
  handleWindowSizeChange: (store) => {
    store.setState({ windowWidth: window.innerWidth });
    store.setState({ windowHeight: window.innerHeight });
  },
};

const useGlobalState = globalHook(React, initialState, actions);

export default useGlobalState;
