import * as yup from 'yup';
import utils from 'utils';

// custom validator

yup.addMethod(yup.string, 'englishCharacters', function (errorMessage) {
  return this.test(`test-english-char`, errorMessage, function (value) {
    const { path, createError } = this;
    const isValid = value ? utils.validator.englishOnlyValidator(value) : true;
    return isValid || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'doubleSpace', function (errorMessage) {
  return this.test(`test-double-space`, errorMessage, function (value) {
    const { path, createError } = this;
    const isValid = value ? !utils.validator.doubleSpaceValidator(value) : true;
    return isValid || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'nikValidator', function (errorMessage) {
  return this.test(`test-nik`, errorMessage, function (value) {
    const { path, createError } = this;
    const isValid = value ? utils.validator.nikValidator(value) : true;
    return isValid || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'mobilePhoneFormat', function (errorMessage) {
  // method for mobile phone format
  return this.test(`test-phone-format`, errorMessage, function (value) {
    const { path, createError } = this;
    const isValid = value ? utils.validator.phoneValidator(value) : true;
    return isValid || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'noMoreThan20mil', function (errorMessage) {
  // method for mobile phone format
  return this.test(`test-phone-format`, errorMessage, function (value: any) {
    const { path, createError } = this;
    let isValid = true;
    const covertedValue = parseInt(value);
    if (covertedValue > 20000000) {
      isValid = false;
    }
    return isValid || createError({ path, message: errorMessage });
  });
});

yup.addMethod(
  yup.string,
  'validatePhoneWithPersonalIdentityEF',
  function (errorMessage) {
    return this.test(`test-phone-validate`, errorMessage, function (value) {
      const { path, createError } = this;
      let isValid = true;
      let message = '';
      const phoneNumber = this.options.context?.phone_number;
      if (phoneNumber === value) {
        isValid = false;
        message = 'Nomor HP harus berbeda dengan nomor HP utama';
      }

      return isValid || createError({ path, message });
    });
  }
);

yup.addMethod(yup.string, 'phoneFamilyInformationEF', function (errorMessage) {
  return this.test(`test-phone-fi`, errorMessage, function () {
    const { path, createError } = this;
    let isValid = true;
    const motherPhoneNumber = this.parent.mother_phone_number;
    const couplePhoneNumber = this.parent.couple_phone_number;

    if (motherPhoneNumber === couplePhoneNumber) {
      isValid = false;
    }

    return isValid || createError({ path, message: 'Nomor HP harus berbeda' });
  });
});

yup.addMethod(yup.string, 'noLeadingZero', function (errorMessage) {
  return this.test(`test-no-zero`, errorMessage, function (value) {
    const { path, createError } = this;
    const isValid = value ? utils.validator.leadingZeroValidator(value) : true;
    return isValid || createError({ path, message: errorMessage });
  });
});

//page personal_identity
const personalIdentityValidation = {
  ktp_image: yup.string().required('Foto KTP tidak boleh kosong'),
  selfie: yup.string().required('Foto Selfie tidak boleh kosong'),
  nik: (yup as any)
    .string()
    .required('KTP tidak boleh kosong')
    .matches(/^[0-9]+$/, 'Nomor KTP Harus berupa angka')
    .min(16, 'Mohon periksa kembali Nomor KTP Anda')
    .max(16, 'Mohon periksa kembali Nomor KTP Anda')
    .nikValidator('Mohon periksa kembali Nomor KTP Anda'),
  phone_number: (yup as any)
    .string()
    .required('Nomor HP aktif tidak boleh kosong')
    .mobilePhoneFormat('Periksa kembali no handphone Anda'),
  place_of_birth: (yup as any)
    .string()
    .required('Tempat lahir tidak boleh kosong')
    .doubleSpace('Tidak boleh double spasi')
    .min(3, 'Minimal 3 karakter')
    .englishCharacters('Mohon gunakan huruf alphabet'),
  gender: yup.string().required('Jenis Kelamin tidak boleh kosong'),
};

//page family_information
const familyInformationValidation = {
  mother_name: (yup as any)
    .string()
    .required('Nama orang tua tidak boleh kosong')
    .min(3, 'Minimal 3 karakter')
    .doubleSpace('Tidak boleh double spasi')
    .matches(/^[aA-zZ\s]+$/, 'Mohon gunakan nama lengkap asli')
    .englishCharacters('Mohon gunakan huruf alphabet'),
  mother_phone_number: (yup as any)
    .string()
    .mobilePhoneFormat('Periksa kembali no handphone Anda')
    .validatePhoneWithPersonalIdentityEF()
    .phoneFamilyInformationEF()
    .required('Nomor HP orang tua tidak boleh kosong'),
  marriage_status: yup.string().required('Status sipil tidak boleh kosong'),
  couple_name: (yup as any)
    .string()
    .nullable().transform((o: any, c: any) => o === "" ? null : c)
    .min(3, 'Minimal 3 karakter')
    .doubleSpace('Tidak boleh double spasi')
    .matches(/^[aA-zZ\s]+$/, 'Mohon gunakan nama lengkap asli')
    .englishCharacters('Mohon gunakan huruf alphabet'),
  couple_phone_number: (yup as any)
    .string()
    .mobilePhoneFormat('Periksa kembali no handphone Anda')
    .validatePhoneWithPersonalIdentityEF()
    .phoneFamilyInformationEF(),
};

//page financial
const financialValidation = {
  expense_per_month: (yup as any)
    .string()
    .required('Total pengeluaran rumah tangga per bulan tidak boleh kosong')
    .noLeadingZero('Tidak boleh diawali angka 0'),
  expenses_monthly_house_rent: (yup as any)
    .string()
    .required('Total cicilan/sewa rumah per bulan tidak boleh kosong')
    .noLeadingZero('Tidak boleh diawali angka 0'),
  debt_installments_per_month: (yup as any)
    .string()
    .required('Total cicilan hutan per bulan tidak boleh kosong')
    .noLeadingZero('Tidak boleh diawali angka 0'),
};

export const schemaPersonalIdentity = yup
  .object()
  .shape(personalIdentityValidation);

export const schemaFamilyInformation = yup
  .object()
  .shape(familyInformationValidation);

export const schemaFinancialValidation = yup
  .object()
  .shape(financialValidation);
