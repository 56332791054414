import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Div } from 'assets/css/styled';
import {
  dropdownItem,
  wrapperDropdownMenu,
  StyledDropdown,
  Error,
} from './styles';
import ChevronDown from 'assets/img/icon/ic-chevron_down.svg';
import loading from 'assets/img/loading.gif';
import {
  background,
  color,
  flexGrow,
  fontSize,
  fontStyle,
} from 'assets/css/stylesValue';
import { textCenter } from 'assets/css/stylesFix';
import { useOuterClick } from 'hooks';

interface Props {
  disabled?: boolean;
  disabledInput?: boolean;
  onSelect?: (value: any) => void;
  options?: any[];
  placeholder?: string;
  placeholderPosition?: 'top' | 'center' | 'bottom';
  error?: string;
  isLoading?: boolean;
  titleClass?: any;
  descriptionClass?: any;
  dropdownClass?: any;
  inputClass?: any;
  defaultValue?: any;
}

// to use this the data options should have id and title. description is optional
const DropdownAutocomplete: React.FC<Props> = ({
  disabled,
  disabledInput,
  isLoading,
  options = [],
  onSelect,
  placeholder = 'Pilih Dokumen',
  placeholderPosition = 'top',
  titleClass,
  descriptionClass,
  dropdownClass,
  inputClass,
  defaultValue,
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [suggestions, setSuggestions] = useState(options);
  const [selected, setSelected] = useState(defaultValue || '');

  // dropdownRef will be called if user click outside dropdown
  const dropdownRef: React.MutableRefObject<any> = useOuterClick(() => {
    if (isOpen) {
      handleClickDropdown();
    }
  });

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
    setSuggestions(options);
  }, [disabled, options]);

  const handleClickDropdown = () => {
    if (disabled) return;

    if (inputRef.current) {
      if (isOpen) {
        inputRef.current.blur();
      } else {
        inputRef.current.focus();
      }
    }

    setIsOpen(!isOpen);
  };

  const handleClickDropdownMenu = (value: any) => {
    if (inputRef.current) inputRef.current.focus();
    setSelected(value);
    setInputValue(value.title);
    //reset filtered suggestion
    setSuggestions(options);

    if (onSelect) onSelect(value);
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  const onInputChange = (e: any) => {
    if (!disabledInput) {
      const userInput = e.currentTarget.value;
      const filteredSuggestions = options.filter(
        (suggestion) =>
          suggestion?.title?.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      setInputValue(userInput);
      setSuggestions(filteredSuggestions);
    }
  };

  return (
    <Div ref={dropdownRef} position='relative' className={dropdownClass}>
      <StyledDropdown
        disabled={disabled}
        backgroundColor={'transparent'}
        isOpen={isOpen}
        position={placeholderPosition}
        onClick={handleClickDropdown}
        error={!!error && !isOpen}
      >
        <div className={flexGrow(1)}>
          <input
            type='text'
            value={inputValue}
            ref={inputRef}
            disabled={disabled}
            onChange={onInputChange}
            placeholder={placeholder}
            className={`${inputClass}`}
          />
        </div>
        <img style={{ paddingTop: '2px' }} src={ChevronDown} alt='dropdown' />
      </StyledDropdown>
      {error && !isOpen && <Error>{error}</Error>}

      {!disabled && (
        <div
          className={`${wrapperDropdownMenu(isOpen)} ${
            !suggestions.length && background('#eee')
          }`}
        >
          {!isLoading ? (
            suggestions.length ? (
              suggestions?.map((item, index) => {
                const isItemSelected = selected?.id === item?.id;
                return (
                  <div
                    key={index}
                    className={`${dropdownItem} ${fontSize('14px')} ${
                      isItemSelected ? color('#fff') : color('#5e5e5e')
                    } ${isItemSelected ? background('#0f9be2') : ''}`}
                    onClick={() => handleClickDropdownMenu(item)}
                  >
                    <div className={`${titleClass}`}>{item?.title}</div>
                    {item?.description && (
                      <div className={`${descriptionClass}`}>
                        {item?.description}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div
                className={`${textCenter} ${fontSize(12)} ${fontStyle(
                  'italic'
                )} ${color('#ccc')}`}
              >
                No items
              </div>
            )
          ) : (
            <div
              className={`${textCenter} ${fontSize(12)} ${fontStyle(
                'italic'
              )} ${color('#ccc')}`}
            >
              <img src={loading} width='25px' height='25px' />
            </div>
          )}
        </div>
      )}
    </Div>
  );
};

export default DropdownAutocomplete;
