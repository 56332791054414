import * as yup from 'yup';
import utils from 'utils';

// custom validator

yup.addMethod(yup.string, 'nikValidator', function (errorMessage) {
    return this.test(`test-nik`, errorMessage, function (value) {
      const { path, createError } = this;
      const isValid = value ? utils.validator.nikValidator(value) : true;
      return isValid || createError({ path, message: errorMessage });
    });
  });


yup.addMethod(yup.string, 'noMoreThan20mil', function (errorMessage) {
  // method for mobile phone format
  return this.test(`test-phone-format`, errorMessage, function (value: any) {
    const { path, createError } = this;
    let isValid = true;
    const covertedValue = parseInt(value);
    if (covertedValue > 20000000) {
      isValid = false;
    }
    return isValid || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'noLeadingZero', function (errorMessage) {
  return this.test(`test-no-zero`, errorMessage, function (value) {
    const { path, createError } = this;
    const isValid = value ? utils.validator.leadingZeroValidator(value) : true;
    return isValid || createError({ path, message: errorMessage });
  });
});

const disbursementValidation = {
  request_loan_amount: (yup as any)
    .string()
    .required('Jumlah pinjaman tidak boleh kosong')
    .noLeadingZero('Tidak boleh diawali angka 0')
    .noMoreThan20mil('Jumlah pinjaman tidak boleh lebih dari 20 juta'),
  nik: (yup as any)
    .string()
    .required('KTP tidak boleh kosong')
    .matches(/^[0-9]+$/, 'Nomor KTP Harus berupa angka')
    .min(16, 'Mohon periksa kembali Nomor KTP Anda')
    .max(16, 'Mohon periksa kembali Nomor KTP Anda')
    .nikValidator('Mohon periksa kembali Nomor KTP Anda'),
  tenor: (yup as any)
    .number()
    .required('Jangka waktu tidak boleh kosong')
};

export const schemaDisbursementValidation = yup
  .object()
  .shape(disbursementValidation);