import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { NAVBAR_MENU } from 'constant';

import { Main } from 'assets/css/styled';
import logoName from 'assets/img/logo-horizontal.svg';

import NavBar from 'components/NavBar';
import AxiataWildCartRoute from './components/AxiataWildCartRoute';

import AxiataRegister from 'pages/partners/axiata/Register';

import AxiataSphp from 'pages/partners/axiata/SPHP';
import AxiataSPHPSuccess from 'pages/partners/axiata/SPHPSuccess';
import AxiataApplicationSuccess from './ApplicationSuccess';
import AxiataPrivacyPolicy from './TnC';

import Application from 'pages/commons/Applications';

import utils from 'utils';
import AxiataGuard from './components/AxiataGuard';

const LinkAjaRoute = () => {
  const savePartnerToStorage = () => {
    utils.store.set('partner', 'axiata');
  };

  useEffect(() => {
    savePartnerToStorage();
  }, []);

  return (
    <Main>
      <NavBar menu={NAVBAR_MENU} logo={logoName} hideMenu />
      <Switch>
        <Route exact component={AxiataRegister} path={`/register`} />

        <Route component={AxiataGuard(Application)} path={`/application`} />
        <Route
          component={AxiataGuard(AxiataApplicationSuccess)}
          path={'/application-success'}
        />
        <Route component={AxiataGuard(AxiataSphp)} path={`/sphp`} />
        <Route
          component={AxiataGuard(AxiataSPHPSuccess)}
          path={'/sphp-success'}
        />
        <Route component={AxiataGuard(AxiataPrivacyPolicy)} path={'/privacy-policy'} />
        <Route exact path='*' component={AxiataWildCartRoute} />
      </Switch>
    </Main>
  );
};

export default LinkAjaRoute;
