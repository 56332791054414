import React from 'react';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router';

import { cardCase } from 'constant';
import { useUserContext } from 'providers/UserProvider';

import { actionItemMenu } from './styles';
import { Card, Col, Div, Img, Row } from 'assets/css/styled';
import {
  alignCenter,
  boxShadowThick,
  boxShadowThin,
  breakWord,
  cursorPointer,
  dFlex,
  dInlineFlex,
  flexColumn,
  fontNormal,
  justifyCenter,
  mt2,
  my4,
  positionAbsolute,
  positionRelative,
  py2,
  py4,
} from 'assets/css/stylesFix';
import {
  backgroundImage,
  borderRadiusTop,
  padding,
  text,
  transition,
  translate,
} from 'assets/css/stylesValue';

import bannerBFI from 'assets/img/background/bg-bfi.jpg';
import iconBFI from 'assets/img/icon/ic-bfi.png';
import iconLocked from 'assets/img/icon/ic-locked.svg';

const ProductInfo = ({
  showDialogInfo,
  setDialogData,
  setShowDialogInfo,
  userData,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const themeText = theme?.text;
  const { datas } = useUserContext();

  const handleClickMenu = (value) => {
    const tempCardCase = cardCase('webpage');
    switch (value?.code) {
      case 1: // Tarik Dana
        history.push('transactions');
        break;

      default:
        setDialogData(tempCardCase);
        setShowDialogInfo(!showDialogInfo);
        break;
    }
  };

  return datas.appStatus == '105' &&
    userData.creditInfo?.credit_score === 'C' ? (
    <Card rounded className={cx(boxShadowThick, my4)}>
      <Div
        className={cx(
          text({ size: 14, color: themeText?.primary, weight: 600 })
        )}
      >
        Rekomendasi produk
      </Div>
      <Row>
        <Col xs='12' sm='6'>
          <Card
            rounded
            padding='0px'
            className={cx(boxShadowThin, cursorPointer, dFlex, flexColumn, mt2)}
            onClick={() =>
              window.location.assign('https://kredit.bfi.co.id/julo/')
            }
          >
            <Div
              className={cx(
                alignCenter,
                backgroundImage(bannerBFI),
                borderRadiusTop(5),
                dFlex,
                justifyCenter,
                padding('40px 0px')
              )}
            >
              <Img src={iconBFI} width='42' height='26' />
            </Div>
            <Div className={cx(padding('15px'))}>
              <Div
                className={cx(
                  text({ size: 20, color: themeText?.primary, weight: 600 })
                )}
              >
                BFI
              </Div>
              <Div
                className={cx(text({ size: 20, color: themeText?.primary }))}
              >
                Pinjaman dengan jaminan
              </Div>
            </Div>
          </Card>
        </Col>
      </Row>
    </Card>
  ) : (
    <Row className={cx(py4)}>
      {userData?.product &&
        userData?.product?.map((item, index) => (
          <Col
            xs='3'
            sm='3'
            key={index}
            className={cx('asdadasd',
              {
                [actionItemMenu]: !item.is_locked,
              },
              cursorPointer,
              text({ align: 'center' }),
              transition('all 0.3s'),
              py2
            )}
            onClick={() => !item.is_locked && handleClickMenu(item)}
          >
            <Div className={cx(dInlineFlex, positionRelative)}>
              <Div className={cx(positionRelative)}>
                {item.background_icon && (
                  <Img
                    className={cx(positionAbsolute)}
                    src={item.background_icon}
                    width='50'
                    height='50'
                  />
                )}
                <Img
                  className={cx(positionRelative)}
                  src={item.foreground_icon}
                  width='50'
                  height='50'
                />
              </Div>
              {item.is_locked && (
                <Img
                  src={iconLocked}
                  width='20'
                  height='20'
                  className={cx(positionAbsolute, translate('160%', '-25%'))}
                />
              )}
            </Div>
            <Div
              className={cx(
                breakWord,
                fontNormal,
                text({ size: 14, color: themeText?.primary, weight: 'bold' })
              )}
            >
              {item.name}
            </Div>
          </Col>
        ))}
    </Row>
  );
};

ProductInfo.propTypes = {
  disabled: PropTypes.bool,
  userData: PropTypes.object,
  showDialogInfo: PropTypes.bool,
  setDialogData: PropTypes.func,
  setShowDialogInfo: PropTypes.func,
};

ProductInfo.defaultProps = {
  userData: {},
  showDialogInfo: false,
  setDialogData: () => {},
  setShowDialogInfo: () => {},
};

export default ProductInfo;
