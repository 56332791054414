import React from 'react';
import PropTypes from 'prop-types';

import { UserProvider } from 'providers/UserProvider';
import { ApplicationProvider } from 'pages/commons/Applications/providers/ApplicationProvider';

import combineProviders from './combineProviders';

/**
 * Combine Internal Custom Provider
 */
const GlobalProviders = combineProviders([UserProvider, ApplicationProvider]);

/**
 * GlobalContextProvider is component like CombineReducer but for Context Provider;
 * @param
 */
const GlobalContextProvider = ({ children }) => {
  return <GlobalProviders>{children}</GlobalProviders>;
};

GlobalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

export default GlobalContextProvider;
