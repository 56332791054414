import React from 'react';
import Lazyload from 'components/Lazyload';

const CreatePinLazy = React.lazy(() =>
  import(/* webpackChunkName: "create-pin-page" */ './CreatePin')
);

const CreatePin = (props) => {
  return <Lazyload component={CreatePinLazy} animationLoading {...props} />;
};

export default CreatePin;
