import { forwardRef } from 'react';
import { cx } from '@emotion/css';
import { FormControl, FormLabel } from '@material-ui/core';

import { FieldGroupProps } from './types';
import { fieldGroupCx } from './styles';

const FieldGroup = forwardRef<HTMLDivElement, FieldGroupProps>((props, ref) => {
  const { children, label, name, className, invalid, error, helperText } =
    props;

  return (
    <FormControl
      className={cx('field-group', className, fieldGroupCx)}
      innerRef={ref}
    >
      {Boolean(label) && (
        <FormLabel className='field-label' htmlFor={name}>
          {label}
        </FormLabel>
      )}
      {children}
      {(invalid || helperText) && (
        <div className='field-info'>
          {invalid && Boolean(error?.message) ? (
            <div className='error field-info-item'>
              <span>{error?.message}</span>
            </div>
          ) : (
            helperText && (
              <div className='helper field-info-item'>
                <span>{helperText}</span>
              </div>
            )
          )}
        </div>
      )}
    </FormControl>
  );
});

export default FieldGroup;
