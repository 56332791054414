import { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

import callAllFn from '@julofinance/web-helpers/dist/fn/callAllFn';
import mergeRefs from '@julofinance/web-helpers/dist/fn/mergeRefs';

import FieldGroup from '../field-group';

import { InputProps } from './types';

const Input = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  const {
    name,
    defaultValue,
    required,
    rules,
    helperText,
    value,
    onChange,
    onBlur,
    className,
    inputClassName,
    inputRef = null,
    type,
    InputLabelProps,
    ...resProps
  } = props;

  const { control } = useFormContext();
  const {
    fieldState: { error, invalid },
    field: {
      ref: controlRef,
      onChange: controllerOnChange,
      onBlur: controllerOnBlur,
      value: controllerValue,
      ...resFieldProps
    },
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      ...(required && { required: 'This field is required' }),
      ...(rules && rules),
    },
  });

  return (
    <FieldGroup
      invalid={invalid}
      error={error}
      helperText={helperText}
      className={className}
      ref={ref}
      name={name}
    >
      <TextField
        {...resProps}
        type={type}
        inputRef={mergeRefs(controlRef, inputRef)}
        className={inputClassName}
        error={invalid}
        value={value ?? controllerValue}
        onChange={callAllFn(onChange, controllerOnChange)}
        onBlur={callAllFn(onBlur, controllerOnBlur)}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
        }}
        {...resFieldProps}
      />
    </FieldGroup>
  );
});

export default Input;
